@import '~bootstrap/scss/functions';
@import '../../styles/variables.scss';

.breadcrumb-item {
    button {
        border: none;
        background: none;
        color: $menu-item-light-active;
    }
}

.breadcrumb > li + li:before {
    content: '|' !important;
}

.breadcrumb:hover {
    cursor: pointer;
}

.btn-disabled,
.btn-disabled[disabled] {
    opacity: 0.4;
    cursor: default !important;
    pointer-events: none;
}
