@import '~bootstrap/scss/functions';
@import '../../styles/variables.scss';

.toggle-switch {
    position: relative;
    width: 75px;

    .checkbox {
        display: none;

        &:checked + .label .inner {
            margin-left: 0;
        }
        &:checked + .label .switch {
            right: 0px;
        }
    }
    .label {
        display: block;
        overflow: hidden;
        cursor: pointer;
        border-radius: 20px;

        .inner {
            display: block;
            width: 200%;
            margin-left: -100%;
            transition: margin 0.1s ease-in 0s;

            &:before,
            &:after {
                float: left;
                width: 50%;
                height: 36px;
                padding: 0;
                line-height: 36px;
                color: #fff;
                font-weight: bold;
                box-sizing: border-box;
            }
            &:before {
                content: 'On';
                padding-left: 15px;
                background-color: $primary;
                color: #fff;
            }
            &:after {
                content: 'Off';
                padding-right: 15px;
                background-color: $danger;
                color: #fff;
                text-align: right;
            }
        }
        .switch {
            width: 24px;
            margin: 5px;
            background: #fff;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 40px;
            border: 0 solid #bbb;
            border-radius: 20px;
            transition: all 0.1s ease-in 0s;
        }
    }
}
