.avatar {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    font-size: 1rem;

    &-md {
        height: 75px;
        width: 75px;
        font-size: 1.5rem;
    }

    &-lg {
        height: 100px;
        width: 100px;
        font-size: 2rem;
    }
}
