.toast {
    &-autohide {
        animation: autohide 4s forwards;
    }
}
@keyframes autohide {
    0% {
        opacity: 1;
    }
    90% {
        opacity: 1;
    }   
    100% {
        opacity: 0;
        display: none;
    }
}