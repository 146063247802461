.popover {
    position: absolute;
    inset: 0px auto auto 0px;
    margin: 0px;
    min-height: 100px;
    width: 200px;
    &-end {
        left: calc(100% + 15px);
        top: 50%;
        transform: translate(0px, -50%);
    }
    &-start {
        left: calc(-100% - 30px);
        top: 50%;
        transform: translate(0px, -50%);
    }
    &-top {
        left: 50%;
        top: -300%;
        transform: translate(-50%, 0);

        .popover-arrow {
            position: absolute;
            top: calc(100% + 5px);
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
    &-bottom {
        left: 50%;
        top: calc(100% + 15px);
        transform: translate(-50%, 0);
        .popover-arrow {
            position: absolute;
            top: -5px;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
    &-arrow {
        position: absolute;
        top: 50%;
        transform: translate(0px, -50%);
    }
}
