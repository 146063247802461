html {
    position: relative;
    min-height: 100%;
}
body {
    overflow-x: hidden;
}
@supports (-webkit-overflow-scrolling: touch) {
    body {
        cursor: pointer;
    }
}
.logo {
    display: block;
    line-height: 70px;
    width: 260px;
    position: fixed;
    top: 0;
}
.logo span.logo-lg {
    display: block;
}
.logo span.logo-sm {
    display: none;
}
.logo.logo-light {
    display: block;
}
.logo.logo-dark {
    display: none;
}
.wrapper {
    height: 100%;
    overflow: hidden;
    width: 100%;
}
.content-page {
    margin-left: 260px;
    overflow: hidden;
    padding: 70px 12px 65px;
    min-height: 100vh;
}
.leftside-menu {
    width: 260px;
    z-index: 10;
    bottom: 0;
    position: fixed;
    top: 0;
    padding-top: 70px;
    -webkit-box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
    box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
}
.side-nav {
    padding-left: 0;
    list-style-type: none;
}
.side-nav ul {
    list-style-type: none;
}
.side-nav .side-nav-link {
    color: #333;
    display: block;
    padding: 10px 30px;
    font-size: 0.9375rem;
    position: relative;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
    list-style: none;
}
.side-nav .side-nav-link:active,
.side-nav .side-nav-link:focus,
.side-nav .side-nav-link:hover {
    color: #fff;
    text-decoration: none;
}
.side-nav .side-nav-link span {
    vertical-align: middle;
}
.side-nav .side-nav-link i {
    display: inline-block;
    line-height: 1.0625rem;
    margin: 0 10px 0 0;
    font-size: 1.1rem;
    vertical-align: middle;
    width: 20px;
}
.side-nav .menu-arrow {
    -webkit-transition: -webkit-transform 0.15s;
    transition: -webkit-transform 0.15s;
    transition: transform 0.15s;
    transition: transform 0.15s, -webkit-transform 0.15s;
    position: absolute;
    right: 30px;
    display: inline-block;
    font-family: 'Material Design Icons';
    text-rendering: auto;
    line-height: 1.5rem;
    font-size: 1.1rem;
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
}
.side-nav .menu-arrow:before {
    content: '\f0142';
}
.side-nav .badge {
    margin-top: 3px;
}
.side-nav .side-nav-item > a[aria-expanded='true'] > span.menu-arrow {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}
.side-nav .side-nav-item.menuitem-active > a:not(.collapsed) > span.menu-arrow {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}
.side-nav .side-nav-title {
    padding: 12px 30px;
    letter-spacing: 0.05em;
    pointer-events: none;
    cursor: default;
    font-size: 0.6875rem;
    text-transform: uppercase;
    color: #333;
    font-weight: 700;
}
.side-nav .menuitem-active > a {
    color: #fff !important;
}
[dir='ltr'] .side-nav .side-nav-item .menu-arrow:before {
    content: '\f0141' !important;
}
.side-nav-forth-level,
.side-nav-second-level,
.side-nav-third-level {
    padding-left: 0;
}
.side-nav-forth-level li .side-nav-link,
.side-nav-forth-level li a,
.side-nav-second-level li .side-nav-link,
.side-nav-second-level li a,
.side-nav-third-level li .side-nav-link,
.side-nav-third-level li a {
    padding: 8px 30px 8px 65px;
    color: #cedce4;
    display: block;
    position: relative;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
    font-size: 0.89rem;
    
}
.side-nav-forth-level li .side-nav-link:focus,
.side-nav-forth-level li .side-nav-link:hover,
.side-nav-forth-level li a:focus,
.side-nav-forth-level li a:hover,
.side-nav-second-level li .side-nav-link:focus,
.side-nav-second-level li .side-nav-link:hover,
.side-nav-second-level li a:focus,
.side-nav-second-level li a:hover,
.side-nav-third-level li .side-nav-link:focus,
.side-nav-third-level li .side-nav-link:hover,
.side-nav-third-level li a:focus,
.side-nav-third-level li a:hover {
    color: #fff;
}
.side-nav-forth-level li .side-nav-link .menu-arrow,
.side-nav-forth-level li a .menu-arrow,
.side-nav-second-level li .side-nav-link .menu-arrow,
.side-nav-second-level li a .menu-arrow,
.side-nav-third-level li .side-nav-link .menu-arrow,
.side-nav-third-level li a .menu-arrow {
    line-height: 1.3rem;
}
.side-nav-forth-level li.active > a,
.side-nav-second-level li.active > a,
.side-nav-third-level li.active > a {
    color: #fff;
}
.side-nav-third-level li .side-nav-link,
.side-nav-third-level li a {
    padding: 8px 30px 8px 80px;
}
.side-nav-forth-level li .side-nav-link,
.side-nav-forth-level li a {
    padding: 8px 30px 8px 100px;
}
body[data-leftbar-compact-mode='condensed']:not(.authentication-bg) {
    min-height: 1600px;
}
body[data-leftbar-compact-mode='condensed']:not(.authentication-bg) .wrapper .leftside-menu {
    position: absolute;
    padding-top: 0;
    width: 70px;
    z-index: 5;
    padding-top: 70px;
}
body[data-leftbar-compact-mode='condensed']:not(.authentication-bg) .wrapper .leftside-menu .simplebar-content-wrapper,
body[data-leftbar-compact-mode='condensed']:not(.authentication-bg) .wrapper .leftside-menu .simplebar-mask {
    overflow: visible !important;
}
body[data-leftbar-compact-mode='condensed']:not(.authentication-bg) .wrapper .leftside-menu .simplebar-scrollbar {
    display: none !important;
}
body[data-leftbar-compact-mode='condensed']:not(.authentication-bg) .wrapper .leftside-menu .simplebar-offset {
    bottom: 0 !important;
}
body[data-leftbar-compact-mode='condensed']:not(.authentication-bg) .wrapper .leftside-menu .logo {
    width: 70px;
    z-index: 1;
    background: linear-gradient(135deg, #6379c3 0, #546ee5 60%);
}
body[data-leftbar-compact-mode='condensed']:not(.authentication-bg) .wrapper .help-box {
    display: none;
}
body[data-leftbar-compact-mode='condensed']:not(.authentication-bg) .wrapper .content-page {
    margin-left: 70px;
}
body[data-leftbar-compact-mode='condensed']:not(.authentication-bg) .wrapper .footer,
body[data-leftbar-compact-mode='condensed']:not(.authentication-bg) .wrapper .navbar-custom {
    left: 70px;
}
body[data-leftbar-compact-mode='condensed']:not(.authentication-bg) .side-nav .badge,
body[data-leftbar-compact-mode='condensed']:not(.authentication-bg) .side-nav .menu-arrow,
body[data-leftbar-compact-mode='condensed']:not(.authentication-bg) .side-nav .side-nav-title {
    display: none !important;
}
body[data-leftbar-compact-mode='condensed']:not(.authentication-bg) .side-nav .collapse,
body[data-leftbar-compact-mode='condensed']:not(.authentication-bg) .side-nav .collapsing {
    display: none;
    height: inherit !important;
    -webkit-transition: none !important;
    transition: none !important;
}
body[data-leftbar-compact-mode='condensed']:not(.authentication-bg) .side-nav .collapse .side-nav-forth-level,
body[data-leftbar-compact-mode='condensed']:not(.authentication-bg) .side-nav .collapse .side-nav-second-level,
body[data-leftbar-compact-mode='condensed']:not(.authentication-bg) .side-nav .collapse .side-nav-third-level,
body[data-leftbar-compact-mode='condensed']:not(.authentication-bg) .side-nav .collapsing .side-nav-forth-level,
body[data-leftbar-compact-mode='condensed']:not(.authentication-bg) .side-nav .collapsing .side-nav-second-level,
body[data-leftbar-compact-mode='condensed']:not(.authentication-bg) .side-nav .collapsing .side-nav-third-level {
    display: none !important;
}
body[data-leftbar-compact-mode='condensed']:not(.authentication-bg) .side-nav .side-nav-item {
    position: relative;
    white-space: nowrap;
}
body[data-leftbar-compact-mode='condensed']:not(.authentication-bg) .side-nav .side-nav-item .side-nav-link {
    padding: 15px 20px;
    min-height: 54px;
    -webkit-transition: none;
    transition: none;
}
body[data-leftbar-compact-mode='condensed']:not(.authentication-bg) .side-nav .side-nav-item .side-nav-link:active,
body[data-leftbar-compact-mode='condensed']:not(.authentication-bg) .side-nav .side-nav-item .side-nav-link:focus,
body[data-leftbar-compact-mode='condensed']:not(.authentication-bg) .side-nav .side-nav-item .side-nav-link:hover {
    color: #fff;
}
body[data-leftbar-compact-mode='condensed']:not(.authentication-bg) .side-nav .side-nav-item .side-nav-link i {
    font-size: 1.125rem;
    margin-right: 20px;
    margin-left: 6px;
}
body[data-leftbar-compact-mode='condensed']:not(.authentication-bg) .side-nav .side-nav-item .side-nav-link span {
    display: none;
    padding-left: 10px;
}
body[data-leftbar-compact-mode='condensed']:not(.authentication-bg) .side-nav .side-nav-item:hover .side-nav-link {
    position: relative;
    width: 260px;
    color: #fff;
    background: linear-gradient(135deg, #6379c3 0, #546ee5 60%);
}
body[data-leftbar-compact-mode='condensed']:not(.authentication-bg) .side-nav .side-nav-item:hover .side-nav-link span {
    display: inline;
}
body[data-leftbar-compact-mode='condensed']:not(.authentication-bg) .side-nav .side-nav-item:hover > ul {
    display: block !important;
    left: 70px;
    position: absolute;
    width: 190px;
    height: auto !important;
    padding: 5px 0;
    z-index: 9999;
    background: linear-gradient(135deg, #6379c3 0, #546ee5 60%);
    -webkit-box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
    box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
}
body[data-leftbar-compact-mode='condensed']:not(.authentication-bg) .side-nav .side-nav-item:hover > ul a {
    padding: 8px 20px;
    position: relative;
    width: 190px;
}
body[data-leftbar-compact-mode='condensed']:not(.authentication-bg) .side-nav .side-nav-item:hover > ul a:hover {
    color: #fff;
}
body[data-leftbar-compact-mode='condensed']:not(.authentication-bg) .side-nav .side-nav-item:hover > .collapse,
body[data-leftbar-compact-mode='condensed']:not(.authentication-bg) .side-nav .side-nav-item:hover > .collapsing {
    display: block !important;
    -webkit-transition: none !important;
    transition: none !important;
}
body[data-leftbar-compact-mode='condensed']:not(.authentication-bg) .side-nav .side-nav-item:hover > .collapse > ul,
body[data-leftbar-compact-mode='condensed']:not(.authentication-bg) .side-nav .side-nav-item:hover > .collapsing > ul {
    display: block !important;
    left: 70px;
    position: absolute;
    width: 190px;
    background: linear-gradient(135deg, #6379c3 0, #546ee5 60%);
    -webkit-box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
    box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
}
body[data-leftbar-compact-mode='condensed']:not(.authentication-bg) .side-nav .side-nav-item:hover > .collapse > ul a,
body[data-leftbar-compact-mode='condensed']:not(.authentication-bg) .side-nav .side-nav-item:hover > .collapsing > ul a {
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 8px 20px;
    position: relative;
    width: 190px;
    z-index: 6;
}
body[data-leftbar-compact-mode='condensed']:not(.authentication-bg) .side-nav .side-nav-item:hover > .collapse > ul a:hover,
body[data-leftbar-compact-mode='condensed']:not(.authentication-bg) .side-nav .side-nav-item:hover > .collapsing > ul a:hover {
    color: #fff;
}
body[data-leftbar-compact-mode='condensed']:not(.authentication-bg) .side-nav .side-nav-item:hover > .collapse > ul li:hover > .collapse,
body[data-leftbar-compact-mode='condensed']:not(.authentication-bg) .side-nav .side-nav-item:hover > .collapsing > ul li:hover > .collapse {
    display: block !important;
    height: auto !important;
    -webkit-transition: none !important;
    transition: none !important;
}
body[data-leftbar-compact-mode='condensed']:not(.authentication-bg) .side-nav .side-nav-item:hover > .collapse > ul li:hover > .collapse > ul,
body[data-leftbar-compact-mode='condensed']:not(.authentication-bg) .side-nav .side-nav-item:hover > .collapsing > ul li:hover > .collapse > ul {
    display: block;
    left: 190px;
    top: 0;
    position: absolute;
    width: 190px;
}
body[data-leftbar-compact-mode='condensed']:not(.authentication-bg) .logo span.logo-lg {
    display: none;
}
body[data-leftbar-compact-mode='condensed']:not(.authentication-bg) .logo span.logo-sm {
    display: block;
    line-height: 70px;
    color: #536de6;
}
@media (max-width: 767.98px) {
    body {
        overflow-x: hidden;
    }
    .leftside-menu {
        -webkit-box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
        box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
        display: none;
        z-index: 10 !important;
    }
    .sidebar-enable .leftside-menu {
        display: block;
    }
    .navbar-nav.navbar-right {
        float: right;
    }
    .content-page {
        margin-left: 0 !important;
        padding: 65px 10px 65px;
    }
    body[data-leftbar-compact-mode='condensed'] .leftside-menu {
        margin-left: 0;
    }
    .logo span.logo-lg {
        display: block;
    }
    .logo span.logo-sm {
        display: none;
    }
}
.help-box {
    border-radius: 5px;
    padding: 20px;
    margin: 65px 25px 25px;
    position: relative;
    background-color: rgba(255, 255, 255, 0.07);
}
.help-box .close-btn {
    position: absolute;
    right: 10px;
    top: 10px;
}
body[data-leftbar-theme='light'] .help-box {
    background-color: #536de6;
}
body[data-leftbar-theme='light'] .logo.logo-light {
    display: none;
}
body[data-leftbar-theme='light'] .logo.logo-dark {
    display: block;
}
body[data-layout='topnav'] .content-page {
    margin-left: 0 !important;
    padding: 0 0 60px 0;
}
body[data-layout-mode='boxed'] {
    background-color: #fff;
}
body[data-layout-mode='boxed'] .wrapper {
    max-width: 1300px;
    margin: 0 auto;
    background-color: #fafbfe;
    -webkit-box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
    box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
}
body[data-layout-mode='boxed'][data-leftbar-compact-mode='condensed'] .logo {
    position: relative;
    margin-top: -70px;
}
@media (min-width: 1200px) {
    body[data-leftbar-compact-mode='scrollable']:not([data-layout='topnav']) {
        padding-bottom: 0;
    }
    body[data-leftbar-compact-mode='scrollable']:not([data-layout='topnav']) .wrapper {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }
    body[data-leftbar-compact-mode='scrollable']:not([data-layout='topnav']) .leftside-menu {
        position: relative;
        min-width: 260px;
        max-width: 260px;
        padding-top: 0;
    }
    body[data-leftbar-compact-mode='scrollable']:not([data-layout='topnav']) .logo {
        position: relative;
        margin-top: 0;
    }
    body[data-leftbar-compact-mode='scrollable']:not([data-layout='topnav']) .content-page {
        margin-left: 0;
        width: 100%;
        padding-bottom: 60px;
    }
}
div[data-layout='detached'] {
    padding-bottom: 0;
}
@media (min-width: 992px) {
    div[data-layout='detached'] .container-fluid,
    div[data-layout='detached'] .container-lg,
    div[data-layout='detached'] .container-md,
    div[data-layout='detached'] .container-sm,
    div[data-layout='detached'] .container-xl,
    div[data-layout='detached'] .container-xxl {
        max-width: 95%;
    }
}
div[data-layout='detached'][data-layout-mode='boxed'] .wrapper {
    max-width: 100%;
}
div[data-layout='detached'] .wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: inherit;
}
div[data-layout='detached'] .content-page {
    margin-left: 0;
    overflow: hidden;
    padding: 0 15px 5px 30px;
    position: relative;
    margin-right: -15px;
    width: 100%;
    padding-bottom: 60px;
}
div[data-layout='detached'] .leftside-menu {
    position: relative;
    background: #fff !important;
    min-width: 260px;
    max-width: 260px;
    -webkit-box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
    box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
    margin-top: 30px;
    padding-top: 0 !important;
    z-index: 1001 !important;
}
div[data-layout='detached'] .leftside-menu .side-nav .side-nav-link {
    color: #6c757d !important;
}
div[data-layout='detached'] .leftside-menu .side-nav .side-nav-link:active,
div[data-layout='detached'] .leftside-menu .side-nav .side-nav-link:focus,
div[data-layout='detached'] .leftside-menu .side-nav .side-nav-link:hover {
    color: #3d73dd !important;
}
div[data-layout='detached'] .leftside-menu .side-nav .side-nav-forth-level li a,
div[data-layout='detached'] .leftside-menu .side-nav .side-nav-second-level li a,
div[data-layout='detached'] .leftside-menu .side-nav .side-nav-third-level li a {
    color: #6c757d;
}
div[data-layout='detached'] .leftside-menu .side-nav .side-nav-forth-level li a:focus,
div[data-layout='detached'] .leftside-menu .side-nav .side-nav-forth-level li a:hover,
div[data-layout='detached'] .leftside-menu .side-nav .side-nav-second-level li a:focus,
div[data-layout='detached'] .leftside-menu .side-nav .side-nav-second-level li a:hover,
div[data-layout='detached'] .leftside-menu .side-nav .side-nav-third-level li a:focus,
div[data-layout='detached'] .leftside-menu .side-nav .side-nav-third-level li a:hover {
    color: #3d73dd;
}
div[data-layout='detached'] .leftside-menu .side-nav .side-nav-forth-level li.mm-active > a,
div[data-layout='detached'] .leftside-menu .side-nav .side-nav-second-level li.mm-active > a,
div[data-layout='detached'] .leftside-menu .side-nav .side-nav-third-level li.mm-active > a {
    color: #3d73dd;
}
div[data-layout='detached'] .leftside-menu .side-nav .menuitem-active > a {
    color: #3d73dd !important;
}
div[data-layout='detached'] .leftside-menu .side-nav .side-nav-title {
    color: #6c757d;
}
div[data-layout='detached'] .leftbar-user {
    padding: 30px 20px;
    text-align: center;
}
div[data-layout='detached'] .leftbar-user .leftbar-user-name {
    font-weight: 700;
    color: #323a46;
    margin-left: 12px;
    margin-top: 8px;
    display: block;
}
@media (max-width: 767.98px) {
    div[data-layout='detached'].sidebar-enable .leftside-menu {
        position: fixed;
        left: 0;
        overflow-y: auto;
        margin-top: 70px;
    }
    div[data-layout='detached'] .wrapper {
        max-width: 100%;
    }
    div[data-layout='detached'] .content-page {
        margin-left: 0 !important;
        padding: 0 10px 60px 10px;
    }
    div[data-layout='detached'] .lang-switch {
        display: none;
    }
    div[data-layout='detached'][data-leftbar-compact-mode='condensed'].sidebar-enable .leftside-menu {
        margin-top: 0;
    }
}
div[data-layout='detached'][data-leftbar-compact-mode='condensed'] .wrapper .leftside-menu {
    max-width: 70px;
    min-width: 70px;
    position: relative;
}
div[data-layout='detached'][data-leftbar-compact-mode='condensed'] .wrapper .leftbar-user {
    display: none;
}
div[data-layout='detached'][data-leftbar-compact-mode='condensed'] .wrapper .content-page {
    margin-left: 0;
}
div[data-layout='detached'][data-leftbar-compact-mode='condensed'] .wrapper .footer {
    left: 0;
}
div[data-layout='detached'][data-leftbar-compact-mode='condensed'] .side-nav .side-nav-item .side-nav-link:active,
div[data-layout='detached'][data-leftbar-compact-mode='condensed'] .side-nav .side-nav-item .side-nav-link:focus,
div[data-layout='detached'][data-leftbar-compact-mode='condensed'] .side-nav .side-nav-item .side-nav-link:hover {
    color: #fff;
}
div[data-layout='detached'][data-leftbar-compact-mode='condensed'] .side-nav .side-nav-item:hover .side-nav-link {
    background: #536de6;
    color: #fff !important;
    -webkit-transition: none;
    transition: none;
}
div[data-layout='detached'][data-leftbar-compact-mode='condensed'] .side-nav .side-nav-item:hover ul {
    background: #fff !important;
    -webkit-box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
    box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
}
div[data-layout='detached'][data-leftbar-compact-mode='condensed'] .side-nav .side-nav-item:hover ul a:hover {
    color: #3d73dd !important;
}
@media (min-width: 992px) {
    div[data-layout='detached'][data-leftbar-compact-mode='scrollable'] .wrapper {
        padding-top: 70px;
    }
}
.button-menu-mobile .lines {
    width: 18px;
    display: block;
    position: relative;
    height: 16px;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    margin-top: 26px;
    margin-left: 10px;
}
.button-menu-mobile span {
    height: 2px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    display: block;
    margin-bottom: 5px;
    -webkit-transition: -webkit-transform 0.5s ease;
    transition: -webkit-transform 0.5s ease;
    transition: transform 0.5s ease;
    transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}
.button-menu-mobile span:nth-of-type(2) {
    width: 24px;
}
.button-menu-mobile.disable-btn {
    display: none;
}
body[data-leftbar-theme='light'] .leftside-menu {
    background: #fff;
}
body[data-leftbar-theme='light'] .leftside-menu .logo {
    background: #fff !important;
}
body[data-leftbar-theme='light'] .side-nav .side-nav-link {
    color: #6c757d;
}
body[data-leftbar-theme='light'] .side-nav .side-nav-link:active,
body[data-leftbar-theme='light'] .side-nav .side-nav-link:focus,
body[data-leftbar-theme='light'] .side-nav .side-nav-link:hover {
    color: #3d73dd;
}
body[data-leftbar-theme='light'] .side-nav .menuitem-active > a {
    color: #3d73dd !important;
}
body[data-leftbar-theme='light'] .side-nav .side-nav-title {
    color: #6c757d;
}
body[data-leftbar-theme='light'] .side-nav .side-nav-forth-level li a,
body[data-leftbar-theme='light'] .side-nav .side-nav-second-level li a,
body[data-leftbar-theme='light'] .side-nav .side-nav-third-level li a {
    color: #6c757d;
}
body[data-leftbar-theme='light'] .side-nav .side-nav-forth-level li a:focus,
body[data-leftbar-theme='light'] .side-nav .side-nav-forth-level li a:hover,
body[data-leftbar-theme='light'] .side-nav .side-nav-second-level li a:focus,
body[data-leftbar-theme='light'] .side-nav .side-nav-second-level li a:hover,
body[data-leftbar-theme='light'] .side-nav .side-nav-third-level li a:focus,
body[data-leftbar-theme='light'] .side-nav .side-nav-third-level li a:hover {
    color: #3d73dd;
}
body[data-leftbar-theme='light'] .side-nav .side-nav-forth-level li.active > a,
body[data-leftbar-theme='light'] .side-nav .side-nav-second-level li.active > a,
body[data-leftbar-theme='light'] .side-nav .side-nav-third-level li.active > a {
    color: #3d73dd;
}
body[data-leftbar-theme='light'][data-leftbar-compact-mode='condensed'] .side-nav .side-nav-item .side-nav-link:active,
body[data-leftbar-theme='light'][data-leftbar-compact-mode='condensed'] .side-nav .side-nav-item .side-nav-link:focus,
body[data-leftbar-theme='light'][data-leftbar-compact-mode='condensed'] .side-nav .side-nav-item .side-nav-link:hover {
    color: #fff;
}
body[data-leftbar-theme='light'][data-leftbar-compact-mode='condensed'] .side-nav .side-nav-item:hover .side-nav-link {
    background: #536de6;
    color: #fff !important;
    -webkit-transition: none;
    transition: none;
}
body[data-leftbar-theme='light'][data-leftbar-compact-mode='condensed'] .side-nav .side-nav-item:hover > ul {
    background: #fff;
    -webkit-box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
    box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
}
body[data-leftbar-theme='light'][data-leftbar-compact-mode='condensed'] .side-nav .side-nav-item:hover > ul a:hover {
    color: #3d73dd;
}
body[data-leftbar-theme='light'][data-leftbar-compact-mode='condensed'] .side-nav .side-nav-item:hover > .collapse > ul,
body[data-leftbar-theme='light'][data-leftbar-compact-mode='condensed'] .side-nav .side-nav-item:hover > .collapsing > ul {
    background: #fff;
}
body[data-leftbar-theme='light'][data-leftbar-compact-mode='condensed'] .side-nav .side-nav-item:hover > .collapse > ul a:hover,
body[data-leftbar-theme='light'][data-leftbar-compact-mode='condensed'] .side-nav .side-nav-item:hover > .collapsing > ul a:hover {
    color: #3d73dd;
}
body[data-leftbar-theme='dark'] .leftside-menu {
    background: #313a46;
}
body[data-leftbar-theme='dark'] .leftside-menu .logo {
    background: #313a46 !important;
}
body[data-leftbar-theme='dark'] .side-nav .side-nav-link {
    color: #8391a2;
}
body[data-leftbar-theme='dark'] .side-nav .side-nav-link:active,
body[data-leftbar-theme='dark'] .side-nav .side-nav-link:focus,
body[data-leftbar-theme='dark'] .side-nav .side-nav-link:hover {
    color: #bccee4;
}
body[data-leftbar-theme='dark'] .side-nav .menuitem-active > a {
    color: #fff !important;
}
body[data-leftbar-theme='dark'] .side-nav .side-nav-title {
    color: #8391a2;
}
body[data-leftbar-theme='dark'] .side-nav .side-nav-forth-level li a,
body[data-leftbar-theme='dark'] .side-nav .side-nav-second-level li a,
body[data-leftbar-theme='dark'] .side-nav .side-nav-third-level li a {
    color: #8391a2;
}
body[data-leftbar-theme='dark'] .side-nav .side-nav-forth-level li a:focus,
body[data-leftbar-theme='dark'] .side-nav .side-nav-forth-level li a:hover,
body[data-leftbar-theme='dark'] .side-nav .side-nav-second-level li a:focus,
body[data-leftbar-theme='dark'] .side-nav .side-nav-second-level li a:hover,
body[data-leftbar-theme='dark'] .side-nav .side-nav-third-level li a:focus,
body[data-leftbar-theme='dark'] .side-nav .side-nav-third-level li a:hover {
    color: #bccee4;
}
body[data-leftbar-theme='dark'] .side-nav .side-nav-forth-level li.active > a,
body[data-leftbar-theme='dark'] .side-nav .side-nav-second-level li.active > a,
body[data-leftbar-theme='dark'] .side-nav .side-nav-third-level li.active > a {
    color: #fff;
}
body[data-leftbar-theme='dark'][data-leftbar-compact-mode='condensed'] .side-nav .side-nav-item .side-nav-link:active,
body[data-leftbar-theme='dark'][data-leftbar-compact-mode='condensed'] .side-nav .side-nav-item .side-nav-link:focus,
body[data-leftbar-theme='dark'][data-leftbar-compact-mode='condensed'] .side-nav .side-nav-item .side-nav-link:hover {
    color: #bccee4;
}
body[data-leftbar-theme='dark'][data-leftbar-compact-mode='condensed'] .side-nav .side-nav-item:hover .side-nav-link {
    background: #536de6;
    color: #fff !important;
    -webkit-transition: none;
    transition: none;
}
body[data-leftbar-theme='dark'][data-leftbar-compact-mode='condensed'] .side-nav .side-nav-item:hover > ul {
    background: #313a46;
    -webkit-box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
    box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
}
body[data-leftbar-theme='dark'][data-leftbar-compact-mode='condensed'] .side-nav .side-nav-item:hover > ul a:hover {
    color: #bccee4;
}
body[data-leftbar-theme='dark'][data-leftbar-compact-mode='condensed'] .side-nav .side-nav-item:hover > .collapse > ul,
body[data-leftbar-theme='dark'][data-leftbar-compact-mode='condensed'] .side-nav .side-nav-item:hover > .collapsing > ul {
    background: #313a46;
}
body[data-layout='full'] .navbar-custom .button-menu-mobile.disable-btn {
    display: block;
}
body[data-layout='full'].hide-menu .wrapper .leftside-menu {
    width: 0 !important;
    padding-top: 0;
}
body[data-layout='full'].hide-menu .wrapper .leftside-menu .side-nav {
    opacity: 0.2;
}
body[data-layout='full'].hide-menu .wrapper .leftside-menu .logo {
    width: 70px;
    background: linear-gradient(135deg, #6379c3 0, #546ee5 60%) !important;
}
body[data-layout='full'].hide-menu .wrapper .leftside-menu .logo span.logo-lg {
    display: none;
}
body[data-layout='full'].hide-menu .wrapper .leftside-menu .logo span.logo-sm {
    display: block;
}
body[data-layout='full'].hide-menu .content-page {
    margin-left: 0 !important;
}
body[data-layout='full'].hide-menu .content-page .navbar-custom {
    left: 70px;
}
body[data-layout='full'].hide-menu .content-page .footer {
    left: 0 !important;
}
body[data-layout='full'].hide-menu[data-leftbar-compact-mode='condensed'] .wrapper .leftside-menu {
    padding-top: 70px;
}
body[data-layout='full'].hide-menu[data-leftbar-compact-mode='condensed'] .wrapper .leftside-menu .side-nav {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    visibility: hidden;
}
body[data-layout='full'][data-leftbar-compact-mode='scrollable'].hide-menu .wrapper .leftside-menu {
    max-width: 0;
    min-width: 0;
}
body[data-layout='full'][data-leftbar-compact-mode='scrollable'] .wrapper .leftside-menu {
    padding-top: 0 !important;
}
body[data-layout='full'][data-leftbar-theme='dark'].hide-menu .wrapper .leftside-menu .logo {
    background: #313a46 !important;
}
body[data-layout='full'][data-leftbar-theme='light'].hide-menu .wrapper .leftside-menu .logo {
    background: #fff !important;
}
.navbar-custom {
    padding: 0 24px;
    background-color: #fff;
    -webkit-box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
    box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
    min-height: 70px;
    position: fixed;
    left: 260px;
    top: 0;
    right: 0;
    z-index: 1001;
}
.navbar-custom .topbar-left {
    background-color: #fff;
    height: 70px;
    position: fixed;
    z-index: 1;
    width: 260px;
    text-align: center;
    top: 0;
    left: 0;
}
.navbar-custom .topbar-left .logo {
    line-height: 70px;
}
.navbar-custom .topbar-left .logo i {
    display: none;
}
.navbar-custom .topbar-menu {
    position: relative;
    z-index: 1;
}
.navbar-custom .topbar-menu li {
    float: left;
    max-height: 70px;
}
.navbar-custom .topbar-menu li .show.nav-link {
    color: #6c757d;
}
.navbar-custom .topbar-menu .nav-link {
    padding: 0;
    color: #98a6ad;
    min-width: 32px;
    display: block;
    text-align: center;
    margin: 0 10px;
    position: relative;
}
.navbar-custom .app-search {
    position: static;
    overflow-y: hidden;
}
.navbar-custom .app-search form {
    padding: calc(32px * 0.5) 5px calc(32px * 0.5) 0;
    overflow: hidden;
    max-width: 320px;
}
.topbar-dropdown .nav-link {
    line-height: 70px;
}
.app-search .form-control {
    border: none;
    height: calc(1.5em + 0.9rem + 2px);
    padding-left: 40px;
    padding-right: 20px;
    background-color: #f1f3fa;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.app-search span.search-icon {
    position: absolute;
    z-index: 9;
    font-size: 20px;
    line-height: 38px;
    left: 10px;
    top: 0;
}
.app-search .input-group-text {
    margin-left: 0;
    z-index: 4;
}
.notification-list {
    margin-left: 0;
}
.notification-list .noti-title {
    background-color: transparent;
    padding: 15px 20px;
}
.notification-list .noti-icon {
    font-size: 22px;
    vertical-align: middle;
    line-height: 70px;
}
.notification-list .noti-icon-badge {
    display: inline-block;
    position: absolute;
    top: 22px;
    right: 6px;
    border-radius: 50%;
    height: 7px;
    width: 7px;
    background-color: #ff5b5b;
}
.notification-list .notify-item {
    padding: 10px 20px;
}
.notification-list .notify-item .notify-icon {
    float: left;
    height: 36px;
    width: 36px;
    line-height: 36px;
    text-align: center;
    margin-right: 10px;
    border-radius: 50%;
    color: #fff;
}
.notification-list .notify-item .notify-details {
    margin-bottom: 0;
    overflow: hidden;
    margin-left: 45px;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.notification-list .notify-item .notify-details b {
    font-weight: 500;
}
.notification-list .notify-item .notify-details .small,
.notification-list .notify-item .notify-details small {
    display: block;
}
.notification-list .notify-item .notify-details span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 13px;
}
.notification-list .notify-item .user-msg {
    margin-left: 45px;
    white-space: normal;
    line-height: 16px;
}
.notification-list .topbar-dropdown-menu .notify-item {
    padding: 7px 20px;
}
.profile-dropdown {
    min-width: 170px;
}
.profile-dropdown i,
.profile-dropdown span {
    vertical-align: middle;
}
.nav-user {
    padding: calc(31px * 0.5) 20px calc(31px * 0.5) 57px !important;
    text-align: left !important;
    position: relative;
    background-color: #fafbfd;
    border: 1px solid #f1f3fa;
    border-width: 0 1px;
    min-height: 70px;
}
.nav-user .account-user-avatar {
    position: absolute;
    top: calc(38px * 0.5);
    left: 15px;
}
.nav-user .account-user-avatar img {
    height: 32px;
    width: 32px;
}
.nav-user .account-position {
    display: block;
    font-size: 12px;
    margin-top: -3px;
}
.nav-user .account-user-name {
    display: block;
    font-weight: 600;
}
.button-menu-mobile {
    border: none;
    color: #323a46;
    height: 70px;
    line-height: 70px;
    width: 60px;
    background-color: transparent;
    font-size: 24px;
    cursor: pointer;
    float: left;
    z-index: 1;
    position: relative;
    margin-left: -24px;
}
.button-menu-mobile.disable-btn {
    display: none;
}
@media (max-width: 767.98px) {
    .button-menu-mobile {
        margin: 0 !important;
    }
}
[data-keep-enlarged='true'] .navbar-custom {
    padding-left: 0;
}
[data-keep-enlarged='true'] .button-menu-mobile {
    margin: 0;
}
[data-keep-enlarged='true'] .button-menu-mobile.disable-btn {
    display: inline-block;
}
@media (max-width: 600px) {
    .navbar-custom .topbar-menu {
        position: initial;
    }
    .navbar-custom .dropdown {
        position: static;
    }
    .navbar-custom .dropdown .dropdown-menu {
        left: 10px !important;
        right: 10px !important;
    }
}
@media (max-width: 1200px) {
    .navbar-custom {
        right: 0;
    }
    .navbar-custom .app-search {
        display: none;
    }
}
@media (max-width: 991.98px) {
    .nav-user {
        padding: 17px 5px 17px 57px !important;
    }
    .nav-user .account-position,
    .nav-user .account-user-name {
        display: none;
    }
}
@media (max-width: 767.98px) {
    .navbar-custom {
        left: 0 !important;
        padding: 0;
    }
    .button-menu-mobile.disable-btn {
        display: inline-block;
    }
}
@media (max-width: 375px) {
    .navbar-custom .topbar-menu .nav-link {
        margin: 0 5px;
    }
    .navbar-custom.topnav-navbar .container-fluid,
    .navbar-custom.topnav-navbar .container-lg,
    .navbar-custom.topnav-navbar .container-md,
    .navbar-custom.topnav-navbar .container-sm,
    .navbar-custom.topnav-navbar .container-xl,
    .navbar-custom.topnav-navbar .container-xxl {
        padding-right: 12px;
        padding-left: 12px;
    }
    .navbar-custom.topnav-navbar .navbar-toggle {
        margin: 27px 3px;
    }
    .navbar-custom.topnav-navbar .button-menu-mobile {
        width: auto;
    }
}
@media (min-width: 992px) {
    body[data-leftbar-compact-mode='scrollable']:not([data-layout='topnav']) .navbar-custom {
        position: absolute;
    }
}
.topnav-navbar-dark {
    background-color: #313a46;
}
.topnav-navbar-dark .nav-user {
    background-color: #3c4655;
    border: 1px solid #414d5d;
}
.topnav-navbar-dark .topbar-menu li .show.nav-link {
    color: #fff !important;
}
.topnav-navbar-dark .app-search .form-control {
    background-color: #3c4655;
    color: #fff;
}
.topnav-navbar-dark .app-search span {
    color: #98a6ad;
}
.topnav-navbar-dark .navbar-toggle span {
    background-color: rgba(255, 255, 255, 0.8) !important;
}
body[data-layout-mode='boxed'] .navbar-custom {
    position: relative;
    left: 0 !important;
    margin: -70px -12px 0;
}
body[data-layout-mode='boxed'][data-layout='topnav'] .navbar-custom {
    margin: 0;
}
.end-bar {
    background-color: #fff;
    -webkit-box-shadow: 0 0 24px 0 rgba(50, 58, 70, 0.1), 0 1px 0 0 rgba(50, 58, 70, 0.08);
    box-shadow: 0 0 24px 0 rgba(50, 58, 70, 0.1), 0 1px 0 0 rgba(50, 58, 70, 0.08);
    display: block;
    position: fixed;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    width: 280px;
    z-index: 9999;
    float: right !important;
    right: -290px;
    top: 0;
    bottom: 0;
    padding-bottom: 80px;
}
.end-bar .rightbar-title {
    background-color: #313a46;
    padding: 27px 25px;
    color: #fff;
}
.end-bar .end-bar-toggle {
    background-color: #444e5a;
    height: 24px;
    width: 24px;
    line-height: 22px;
    color: #fff;
    text-align: center;
    border-radius: 50%;
    margin-top: -4px;
}
.rightbar-overlay {
    background-color: #37404a;
    opacity: 0.25;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: none;
    z-index: 9998;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}
.end-bar-enabled .end-bar {
    right: 0;
}
.end-bar-enabled .rightbar-overlay {
    display: block;
}
@media (max-width: 767.98px) {
    .end-bar {
        overflow: auto;
    }
}
.page-title-box .page-title {
    font-size: 18px;
    margin: 0;
    line-height: 75px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: inherit;
}
.page-title-box .page-title-right {
    float: right;
    margin-top: 20px;
}
.page-title-box .breadcrumb {
    padding-top: 8px;
}
.page-title-box-sm .page-title {
    line-height: 1 !important;
    margin-bottom: 25px;
}
.page-title-box-sm .page-title-right {
    float: right;
    margin-top: 0;
}
.page-title-box-sm .breadcrumb {
    padding-top: 0;
    margin-top: -3px !important;
}
.text-title {
    color: #6c757d;
}
.text-title:hover {
    color: #6c757d;
}
@media (max-width: 767.98px) {
    .page-title-box .page-title {
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        line-height: 70px;
    }
    .page-title-box .breadcrumb {
        display: none;
    }
    .page-title-box .page-title-right {
        display: none;
    }
}
@media (max-width: 419px) {
    .page-title-box .breadcrumb {
        display: none;
    }
}
.footer {
    border-top: 1px solid rgba(152, 166, 173, 0.2);
    bottom: 0;
    padding: 19px 24px 20px;
    position: absolute;
    right: 0;
    color: #98a6ad;
    left: 260px;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}
.footer .footer-links a {
    color: #98a6ad;
    margin-left: 1.5rem;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
}
.footer .footer-links a:hover {
    color: #323a46;
}
.footer .footer-links a:first-of-type {
    margin-left: 0;
}
@media (max-width: 767.98px) {
    .footer {
        left: 0 !important;
        text-align: center;
    }
}
.footer-alt {
    left: 0;
    border: none;
    text-align: center;
}
body[data-layout='topnav'] .footer {
    left: 0 !important;
    padding: 19px 0 20px;
}
body[data-layout='topnav'][data-layout-mode='boxed'] .footer {
    max-width: 1300px;
}
body[data-layout-mode='boxed'] .footer {
    border: none;
    margin: 0 auto;
    background-color: #fafbfe;
    -webkit-box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
    box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
    max-width: calc(1300px - 260px);
}
body[data-layout-mode='boxed'][data-leftbar-compact-mode='condensed'] .footer {
    max-width: calc(1300px - 70px);
}
div[data-layout='detached'] .footer {
    left: 0;
}
div[data-layout='detached'] .footer .container-fluid,
div[data-layout='detached'] .footer .container-lg,
div[data-layout='detached'] .footer .container-md,
div[data-layout='detached'] .footer .container-sm,
div[data-layout='detached'] .footer .container-xl,
div[data-layout='detached'] .footer .container-xxl {
    max-width: 100%;
    padding: 0;
}
.topnav-navbar {
    padding: 0;
    margin: 0;
    min-height: 70px;
    position: relative;
    left: 0 !important;
    z-index: 1002;
}
.topnav-navbar .topnav-logo {
    line-height: 70px;
    float: left;
    padding-right: 20px;
    min-width: 160px;
}
.topnav-navbar .topnav-logo .topnav-logo-sm {
    display: none;
}
.topnav-navbar .navbar-toggle {
    position: relative;
    cursor: pointer;
    float: left;
    margin: 27px 20px;
    padding: 0;
    background-color: transparent;
    border: none;
}
.topnav-navbar .navbar-toggle .lines {
    width: 25px;
    display: block;
    position: relative;
    height: 16px;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}
.topnav-navbar .navbar-toggle span {
    height: 2px;
    width: 100%;
    background-color: rgba(50, 58, 70, 0.8);
    display: block;
    margin-bottom: 5px;
    -webkit-transition: -webkit-transform 0.5s ease;
    transition: -webkit-transform 0.5s ease;
    transition: transform 0.5s ease;
    transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}
.topnav-navbar .navbar-toggle.open span {
    position: absolute;
}
.topnav-navbar .navbar-toggle.open span:first-child {
    top: 7px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}
.topnav-navbar .navbar-toggle.open span:nth-child(2) {
    visibility: hidden;
}
.topnav-navbar .navbar-toggle.open span:last-child {
    width: 100%;
    top: 7px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
.topnav-navbar .app-search {
    float: left;
}
.topnav {
    background: -webkit-gradient(linear, left top, left bottom, from(#6379c3), to(#546ee5));
    background: linear-gradient(to bottom, #6379c3, #546ee5);
}
.topnav .topnav-menu {
    margin: 0;
    padding: 0;
}
.topnav .navbar-nav .nav-link {
    font-size: 0.9375rem;
    position: relative;
    padding: 1rem 1.3rem;
}
.topnav .nav-item.active > a {
    color: #3d73dd;
}
.topnav .navbar-dark .dropdown.active > .nav-link,
.topnav .navbar-dark .dropdown:active > .nav-link {
    color: #fff;
}
.arrow-down {
    display: inline-block;
}
.arrow-down:after {
    border-color: initial;
    border-style: solid;
    border-width: 0 0 1px 1px;
    content: '';
    height: 0.4em;
    display: inline-block;
    right: 5px;
    top: 50%;
    margin-left: 10px;
    -webkit-transform: rotate(-45deg) translateY(-50%);
    transform: rotate(-45deg) translateY(-50%);
    -webkit-transform-origin: top;
    transform-origin: top;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    width: 0.4em;
}
body[data-layout='topnav'] .container-fluid,
body[data-layout='topnav'] .container-lg,
body[data-layout='topnav'] .container-md,
body[data-layout='topnav'] .container-sm,
body[data-layout='topnav'] .container-xl,
body[data-layout='topnav'] .container-xxl {
    padding-right: 24px;
    padding-left: 24px;
}
@media (min-width: 992px) {
    body[data-layout='topnav'] .container-fluid,
    body[data-layout='topnav'] .container-lg,
    body[data-layout='topnav'] .container-md,
    body[data-layout='topnav'] .container-sm,
    body[data-layout='topnav'] .container-xl,
    body[data-layout='topnav'] .container-xxl {
        max-width: 95%;
    }
    body[data-layout='topnav'][data-layout-mode='boxed'] .container-fluid,
    body[data-layout='topnav'][data-layout-mode='boxed'] .container-lg,
    body[data-layout='topnav'][data-layout-mode='boxed'] .container-md,
    body[data-layout='topnav'][data-layout-mode='boxed'] .container-sm,
    body[data-layout='topnav'][data-layout-mode='boxed'] .container-xl,
    body[data-layout='topnav'][data-layout-mode='boxed'] .container-xxl {
        max-width: 97%;
    }
    .topnav .navbar-nav .nav-item:first-of-type .nav-link {
        padding-left: 0;
    }
    .topnav .dropdown .dropdown-menu {
        margin-top: 0;
        border-radius: 0 0 0.25rem 0.25rem;
        min-width: calc(10rem + 1.5rem);
        font-size: calc(0.9rem - 0.01rem);
    }
    .topnav .dropdown .dropdown-menu .arrow-down::after {
        right: 15px;
        -webkit-transform: rotate(-135deg) translateY(-50%);
        transform: rotate(-135deg) translateY(-50%);
        position: absolute;
    }
    .topnav .dropdown .dropdown-menu .dropdown .dropdown-menu {
        position: absolute;
        top: 0;
        left: 100%;
        display: none;
    }
    .topnav .dropdown:hover > .dropdown-menu {
        display: block;
    }
    .topnav .dropdown:hover > .dropdown-menu > .dropdown:hover > .dropdown-menu {
        display: block;
    }
    .dropdown.active > a.dropdown-item {
        color: #323a46;
        background-color: #f2f5f9;
    }
}
@media (min-width: 1400px) {
    body[data-layout='topnav'] .container-fluid,
    body[data-layout='topnav'] .container-lg,
    body[data-layout='topnav'] .container-md,
    body[data-layout='topnav'] .container-sm,
    body[data-layout='topnav'] .container-xl,
    body[data-layout='topnav'] .container-xxl {
        max-width: 85%;
    }
}
@media (min-width: 992px) {
    .navbar-toggle {
        display: none;
    }
}
@media (max-width: 991.98px) {
    .topnav-navbar .topnav-logo-lg {
        display: none;
    }
    .topnav-navbar .topnav-logo {
        min-width: 50px;
        padding-right: 0;
        text-align: center;
    }
    .topnav-navbar .topnav-logo-sm {
        display: block !important;
    }
    .topnav .navbar-nav .nav-link {
        padding: 0.75rem 1.3rem;
    }
    .topnav .dropdown .dropdown-menu {
        background-color: transparent;
        border: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        padding-left: 15px;
    }
    .topnav .dropdown .dropdown-item {
        position: relative;
        background-color: transparent;
    }
    .topnav .navbar-dark .dropdown .dropdown-item {
        color: rgba(255, 255, 255, 0.5);
    }
    .topnav .navbar-dark .dropdown .dropdown-item.active,
    .topnav .navbar-dark .dropdown .dropdown-item:active {
        color: #fff;
    }
    .topnav .arrow-down::after {
        right: 15px;
        position: absolute;
    }
}

.custom-accordion .card {
    -webkit-box-shadow: none;
    box-shadow: none;
}
.custom-accordion .card-header {
    background-color: #f1f3fa;
}
.custom-accordion .card-body {
    border: 1px solid #f1f3fa;
}
.custom-accordion .accordion-arrow {
    font-size: 1.2rem;
    position: absolute;
    right: 0;
}
.custom-accordion a.collapsed i.accordion-arrow:before {
    content: '\f142';
}
.custom-accordion-title {
    color: #313a46;
    position: relative;
}
.custom-accordion-title:hover {
    color: #414d5d;
}
.accordion > .card > .card-header {
    border-radius: 0;
    margin-bottom: -1px;
}
.avatar-xs {
    height: 1.5rem;
    width: 1.5rem;
}
.avatar-sm {
    height: 3rem;
    width: 3rem;
}
.avatar-md {
    height: 4.5rem;
    width: 4.5rem;
}
.avatar-lg {
    height: 6rem;
    width: 6rem;
}
.avatar-xl {
    height: 7.5rem;
    width: 7.5rem;
}
.avatar-title {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #536de6;
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-weight: 600;
    height: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
}
.breadcrumb-item + .breadcrumb-item::before {
    font-family: 'Material Design Icons';
    font-size: 16px;
    line-height: 1.3;
}
.btn .mdi:before {
    line-height: initial;
}
.btn-primary {
    -webkit-box-shadow: 0 0 0 rgba(83, 109, 230, 0.5);
    box-shadow: 0 0 0 rgba(83, 109, 230, 0.5);
}
.btn-secondary {
    -webkit-box-shadow: 0 0 0 rgba(108, 117, 125, 0.5);
    box-shadow: 0 0 0 rgba(108, 117, 125, 0.5);
}
.btn-success {
    -webkit-box-shadow: 0 0 0 rgba(16, 196, 105, 0.5);
    box-shadow: 0 0 0 rgba(16, 196, 105, 0.5);
}
.btn-info {
    -webkit-box-shadow: 0 0 0 rgba(53, 184, 224, 0.5);
    box-shadow: 0 0 0 rgba(53, 184, 224, 0.5);
}
.btn-warning {
    -webkit-box-shadow: 0 0 0 rgba(249, 200, 81, 0.5);
    box-shadow: 0 0 0 rgba(249, 200, 81, 0.5);
}
.btn-danger {
    -webkit-box-shadow: 0 0 0 rgba(255, 91, 91, 0.5);
    box-shadow: 0 0 0 rgba(255, 91, 91, 0.5);
}
.btn-light {
    -webkit-box-shadow: 0 0 0 rgba(238, 242, 247, 0.5);
    box-shadow: 0 0 0 rgba(238, 242, 247, 0.5);
}
.btn-dark {
    -webkit-box-shadow: 0 0 0 rgba(50, 58, 70, 0.5);
    box-shadow: 0 0 0 rgba(50, 58, 70, 0.5);
}
.badge-primary-lighten {
    color: #536de6;
    background-color: rgba(83, 109, 230, 0.18);
}
.badge-primary-lighten[href] {
    color: #536de6;
    text-decoration: none;
    background-color: rgba(83, 109, 230, 0.18);
}
.badge-primary-lighten[href]:focus,
.badge-primary-lighten[href]:hover {
    color: #536de6;
    text-decoration: none;
    background-color: rgba(83, 109, 230, 0.4);
}
.badge-secondary-lighten {
    color: #6c757d;
    background-color: rgba(108, 117, 125, 0.18);
}
.badge-secondary-lighten[href] {
    color: #6c757d;
    text-decoration: none;
    background-color: rgba(108, 117, 125, 0.18);
}
.badge-secondary-lighten[href]:focus,
.badge-secondary-lighten[href]:hover {
    color: #6c757d;
    text-decoration: none;
    background-color: rgba(108, 117, 125, 0.4);
}
.badge-success-lighten {
    color: #10c469;
    background-color: rgba(16, 196, 105, 0.18);
}
.badge-success-lighten[href] {
    color: #10c469;
    text-decoration: none;
    background-color: rgba(16, 196, 105, 0.18);
}
.badge-success-lighten[href]:focus,
.badge-success-lighten[href]:hover {
    color: #10c469;
    text-decoration: none;
    background-color: rgba(16, 196, 105, 0.4);
}
.badge-info-lighten {
    color: #35b8e0;
    background-color: rgba(53, 184, 224, 0.18);
}
.badge-info-lighten[href] {
    color: #35b8e0;
    text-decoration: none;
    background-color: rgba(53, 184, 224, 0.18);
}
.badge-info-lighten[href]:focus,
.badge-info-lighten[href]:hover {
    color: #35b8e0;
    text-decoration: none;
    background-color: rgba(53, 184, 224, 0.4);
}
.badge-warning-lighten {
    color: #f9c851;
    background-color: rgba(249, 200, 81, 0.18);
}
.badge-warning-lighten[href] {
    color: #f9c851;
    text-decoration: none;
    background-color: rgba(249, 200, 81, 0.18);
}
.badge-warning-lighten[href]:focus,
.badge-warning-lighten[href]:hover {
    color: #f9c851;
    text-decoration: none;
    background-color: rgba(249, 200, 81, 0.4);
}
.badge-danger-lighten {
    color: #ff5b5b;
    background-color: rgba(255, 91, 91, 0.18);
}
.badge-danger-lighten[href] {
    color: #ff5b5b;
    text-decoration: none;
    background-color: rgba(255, 91, 91, 0.18);
}
.badge-danger-lighten[href]:focus,
.badge-danger-lighten[href]:hover {
    color: #ff5b5b;
    text-decoration: none;
    background-color: rgba(255, 91, 91, 0.4);
}
.badge-light-lighten {
    color: #eef2f7;
    background-color: rgba(238, 242, 247, 0.18);
}
.badge-light-lighten[href] {
    color: #eef2f7;
    text-decoration: none;
    background-color: rgba(238, 242, 247, 0.18);
}
.badge-light-lighten[href]:focus,
.badge-light-lighten[href]:hover {
    color: #eef2f7;
    text-decoration: none;
    background-color: rgba(238, 242, 247, 0.4);
}
.badge-dark-lighten {
    color: #323a46;
    background-color: rgba(50, 58, 70, 0.18);
}
.badge-dark-lighten[href] {
    color: #323a46;
    text-decoration: none;
    background-color: rgba(50, 58, 70, 0.18);
}
.badge-dark-lighten[href]:focus,
.badge-dark-lighten[href]:hover {
    color: #323a46;
    text-decoration: none;
    background-color: rgba(50, 58, 70, 0.4);
}
.badge-outline-primary {
    color: #536de6;
    border: 1px solid #536de6;
    background-color: transparent;
}
.badge-outline-primary[href] {
    color: #536de6;
    text-decoration: none;
    background-color: rgba(83, 109, 230, 0.2);
}
.badge-outline-primary[href]:focus,
.badge-outline-primary[href]:hover {
    color: #536de6;
    text-decoration: none;
    background-color: rgba(83, 109, 230, 0.2);
}
.badge-outline-secondary {
    color: #6c757d;
    border: 1px solid #6c757d;
    background-color: transparent;
}
.badge-outline-secondary[href] {
    color: #6c757d;
    text-decoration: none;
    background-color: rgba(108, 117, 125, 0.2);
}
.badge-outline-secondary[href]:focus,
.badge-outline-secondary[href]:hover {
    color: #6c757d;
    text-decoration: none;
    background-color: rgba(108, 117, 125, 0.2);
}
.badge-outline-success {
    color: #10c469;
    border: 1px solid #10c469;
    background-color: transparent;
}
.badge-outline-success[href] {
    color: #10c469;
    text-decoration: none;
    background-color: rgba(16, 196, 105, 0.2);
}
.badge-outline-success[href]:focus,
.badge-outline-success[href]:hover {
    color: #10c469;
    text-decoration: none;
    background-color: rgba(16, 196, 105, 0.2);
}
.badge-outline-info {
    color: #35b8e0;
    border: 1px solid #35b8e0;
    background-color: transparent;
}
.badge-outline-info[href] {
    color: #35b8e0;
    text-decoration: none;
    background-color: rgba(53, 184, 224, 0.2);
}
.badge-outline-info[href]:focus,
.badge-outline-info[href]:hover {
    color: #35b8e0;
    text-decoration: none;
    background-color: rgba(53, 184, 224, 0.2);
}
.badge-outline-warning {
    color: #f9c851;
    border: 1px solid #f9c851;
    background-color: transparent;
}
.badge-outline-warning[href] {
    color: #f9c851;
    text-decoration: none;
    background-color: rgba(249, 200, 81, 0.2);
}
.badge-outline-warning[href]:focus,
.badge-outline-warning[href]:hover {
    color: #f9c851;
    text-decoration: none;
    background-color: rgba(249, 200, 81, 0.2);
}
.badge-outline-danger {
    color: #ff5b5b;
    border: 1px solid #ff5b5b;
    background-color: transparent;
}
.badge-outline-danger[href] {
    color: #ff5b5b;
    text-decoration: none;
    background-color: rgba(255, 91, 91, 0.2);
}
.badge-outline-danger[href]:focus,
.badge-outline-danger[href]:hover {
    color: #ff5b5b;
    text-decoration: none;
    background-color: rgba(255, 91, 91, 0.2);
}
.badge-outline-light {
    color: #eef2f7;
    border: 1px solid #eef2f7;
    background-color: transparent;
}
.badge-outline-light[href] {
    color: #eef2f7;
    text-decoration: none;
    background-color: rgba(238, 242, 247, 0.2);
}
.badge-outline-light[href]:focus,
.badge-outline-light[href]:hover {
    color: #eef2f7;
    text-decoration: none;
    background-color: rgba(238, 242, 247, 0.2);
}
.badge-outline-dark {
    color: #323a46;
    border: 1px solid #323a46;
    background-color: transparent;
}
.badge-outline-dark[href] {
    color: #323a46;
    text-decoration: none;
    background-color: rgba(50, 58, 70, 0.2);
}
.badge-outline-dark[href]:focus,
.badge-outline-dark[href]:hover {
    color: #323a46;
    text-decoration: none;
    background-color: rgba(50, 58, 70, 0.2);
}
.card {
    border: none;
    -webkit-box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
    box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
    margin-bottom: 24px;
}
.card .header-title {
    margin-bottom: 0.5rem;
    text-transform: uppercase;
    letter-spacing: 0.02em;
    font-size: 0.9rem;
    margin-top: 0;
}
.card .card-drop {
    font-size: 20px;
    line-height: 0;
    color: inherit;
}
.card .card-widgets {
    float: right;
    height: 16px;
}
.card .card-widgets > a {
    color: inherit;
    font-size: 18px;
    display: inline-block;
    line-height: 1;
}
.card .card-widgets > a.collapsed i:before {
    content: '\f0415';
}
.card-header,
.card-title {
    margin-top: 0;
}
.card-disabled {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 0.25rem;
    background: rgba(255, 255, 255, 0.8);
    cursor: progress;
}
.card-disabled .card-portlets-loader {
    background-color: #313a46;
    -webkit-animation: rotatebox 1.2s infinite ease-in-out;
    animation: rotatebox 1.2s infinite ease-in-out;
    height: 30px;
    width: 30px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -12px;
    margin-top: -12px;
}
@-webkit-keyframes rotatebox {
    0% {
        -webkit-transform: perspective(120px) rotateX(0) rotateY(0);
        transform: perspective(120px) rotateX(0) rotateY(0);
    }
    50% {
        -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0);
        transform: perspective(120px) rotateX(-180.1deg) rotateY(0);
    }
    100% {
        -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
        transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }
}
@keyframes rotatebox {
    0% {
        -webkit-transform: perspective(120px) rotateX(0) rotateY(0);
        transform: perspective(120px) rotateX(0) rotateY(0);
    }
    50% {
        -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0);
        transform: perspective(120px) rotateX(-180.1deg) rotateY(0);
    }
    100% {
        -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
        transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }
}
.card-pricing {
    position: relative;
}
.card-pricing .card-pricing-plan-name {
    padding-bottom: 20px;
}
.card-pricing .card-pricing-icon {
    font-size: 22px;
    -webkit-box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
    box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
    height: 60px;
    display: inline-block;
    width: 60px;
    line-height: 56px;
    border-radius: 50%;
}
.card-pricing .card-pricing-price {
    padding: 30px 0 0;
}
.card-pricing .card-pricing-price span {
    font-size: 40%;
    color: #98a6ad;
    letter-spacing: 2px;
    text-transform: uppercase;
}
.card-pricing .card-pricing-features {
    color: #98a6ad;
    list-style: none;
    margin: 0;
    padding: 20px 0 0 0;
}
.card-pricing .card-pricing-features li {
    padding: 15px;
}
@media (min-width: 992px) {
    .card-pricing-recommended {
        margin-top: -1.9375rem;
    }
}
.card-pricing-recommended .card-pricing-plan-tag {
    background-color: rgba(255, 91, 91, 0.2);
    color: #ff5b5b;
    padding: 5px 0;
    font-weight: 700;
    border-radius: 0.25rem 0.25rem 0 0;
    margin: -1.5rem -1.5rem 1.5rem -1.5rem;
}
.card-h-100 {
    height: calc(100% - 24px);
}
.form-checkbox-primary .form-check-input:checked,
.form-radio-primary .form-check-input:checked {
    background-color: #536de6;
    border-color: #536de6;
}
.form-checkbox-secondary .form-check-input:checked,
.form-radio-secondary .form-check-input:checked {
    background-color: #6c757d;
    border-color: #6c757d;
}
.form-checkbox-success .form-check-input:checked,
.form-radio-success .form-check-input:checked {
    background-color: #10c469;
    border-color: #10c469;
}
.form-checkbox-info .form-check-input:checked,
.form-radio-info .form-check-input:checked {
    background-color: #35b8e0;
    border-color: #35b8e0;
}
.form-checkbox-warning .form-check-input:checked,
.form-radio-warning .form-check-input:checked {
    background-color: #f9c851;
    border-color: #f9c851;
}
.form-checkbox-danger .form-check-input:checked,
.form-radio-danger .form-check-input:checked {
    background-color: #ff5b5b;
    border-color: #ff5b5b;
}
.form-checkbox-light .form-check-input:checked,
.form-radio-light .form-check-input:checked {
    background-color: #eef2f7;
    border-color: #eef2f7;
}
.form-checkbox-dark .form-check-input:checked,
.form-radio-dark .form-check-input:checked {
    background-color: #323a46;
    border-color: #323a46;
}
.dropdown-menu {
    -webkit-box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
    box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
}
.dropdown-menu-animated.dropdown-menu-end[style] {
    left: auto !important;
    right: 0 !important;
}
.dropdown-menu-animated {
    -webkit-animation-name: DropDownSlide;
    animation-name: DropDownSlide;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    position: absolute;
    margin: 0;
    z-index: 1000;
}
.dropdown-menu-animated.show {
    top: 100% !important;
}
.dropdown-menu-animated i {
    display: inline-block;
}
.dropdown-menu-animated.dropdown-menu[data-popper-placement^='left'],
.dropdown-menu-animated.dropdown-menu[data-popper-placement^='right'],
.dropdown-menu-animated.dropdown-menu[data-popper-placement^='top'] {
    top: auto !important;
    -webkit-animation: none !important;
    animation: none !important;
}
@-webkit-keyframes DropDownSlide {
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    0% {
        -webkit-transform: translateY(10px);
        transform: translateY(10px);
    }
}
@keyframes DropDownSlide {
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    0% {
        -webkit-transform: translateY(10px);
        transform: translateY(10px);
    }
}
@media (min-width: 600px) {
    .dropdown-lg {
        width: 320px;
    }
}
.dropdown-icon-item {
    display: block;
    border-radius: 3px;
    line-height: 34px;
    text-align: center;
    padding: 15px 0 9px;
    display: block;
    border: 1px solid transparent;
    color: #6c757d;
}
.dropdown-icon-item img {
    height: 24px;
}
.dropdown-icon-item span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.dropdown-icon-item:hover {
    background-color: #f4f6fb;
    color: #2d343f;
}
.arrow-none:after {
    display: none;
}
.hljs {
    display: block;
    overflow-x: auto;
    padding: 2em;
    color: #323a46;
    max-height: 420px;
    margin: -10px 0 -30px;
    border: 1px solid rgba(152, 166, 173, 0.2);
}
.hljs-keyword,
.hljs-selector-tag,
.hljs-subst {
    color: #323a46;
    font-weight: 700;
}
.hljs-literal,
.hljs-number,
.hljs-tag .hljs-attr,
.hljs-template-variable,
.hljs-variable {
    color: #02a8b5;
}
.hljs-doctag,
.hljs-string {
    color: #ff5b5b;
}
.hljs-section,
.hljs-selector-id,
.hljs-title {
    color: #ff5b5b;
    font-weight: 700;
}
.hljs-subst {
    font-weight: 400;
}
.hljs-attribute,
.hljs-name,
.hljs-tag {
    color: #1f4faf;
    font-weight: 400;
}
.hljs-link,
.hljs-regexp {
    color: #02a8b5;
}
.hljs-bullet,
.hljs-symbol {
    color: #ff679b;
}
.hljs-built_in,
.hljs-builtin-name {
    color: #35b8e0;
}
.hljs-meta {
    color: #343a40;
    font-weight: 700;
}
.hljs-deletion {
    background: #fdd;
}
.hljs-addition {
    background: #dfd;
}
.hljs-emphasis {
    font-style: italic;
}
.hljs-strong {
    font-weight: 700;
}
.hljs-comment {
    color: #ced4da;
}
.form-control-light {
    background-color: #f1f3fa !important;
    border-color: #f1f3fa !important;
}
input.form-control[type='color'],
input.form-control[type='range'] {
    min-height: 39px;
}
.custom-select.is-invalid:focus,
.custom-select.is-valid:focus,
.custom-select:invalid:focus,
.custom-select:valid:focus,
.form-control.is-invalid:focus,
.form-control.is-valid:focus,
.form-control:invalid:focus,
.form-control:valid:focus {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}
select.form-control:not([size]):not([multiple]) {
    height: calc(1.5em + 0.9rem + 2px);
}
select.form-control-sm:not([size]):not([multiple]) {
    height: calc(1.5em + 0.56rem + 2px);
}
.password-eye:before {
    font-family: 'Material Design Icons';
    content: '\f06d0';
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    vertical-align: middle;
    line-height: 1.2;
    font-size: 16px;
}
.show-password .password-eye:before {
    content: '\f06d1';
}
.modal-title {
    margin-top: 0;
}
.modal-full-width {
    width: 95%;
    max-width: none;
}
.modal-top {
    margin: 0 auto;
}
.modal-right {
    position: absolute;
    right: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100%;
    margin: 0;
    background-color: #fff;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-transform: translate(25%, 0) !important;
    transform: translate(25%, 0) !important;
}
.modal-right button.btn-close {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 1;
}
.modal.show .modal-left,
.modal.show .modal-right {
    -webkit-transform: translate(0, 0) !important;
    transform: translate(0, 0) !important;
}
.modal-bottom {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap;
    -ms-flex-pack: end;
    -webkit-box-pack: end;
    justify-content: flex-end;
    height: 100%;
    margin: 0 auto;
    -ms-flex-line-pack: center;
    align-content: center;
}
.modal-colored-header {
    color: #fff;
    border-radius: 0;
}
.modal-colored-header .btn-close {
    color: #fff !important;
}
.modal-filled {
    color: #fff;
}
.modal-filled .modal-header {
    background-color: rgba(255, 255, 255, 0.07);
}
.modal-filled .modal-footer,
.modal-filled .modal-header {
    border: none;
}
.modal-filled .btn-close {
    color: #fff !important;
}
.nav-pills > li > a,
.nav-tabs > li > a {
    color: #6c757d;
    font-weight: 600;
}
.nav-pills > a {
    color: #6c757d;
    font-weight: 600;
}
.bg-nav-pills {
    background-color: #eef2f7;
}
.nav-tabs.nav-bordered {
    border-bottom: 2px solid rgba(152, 166, 173, 0.2);
}
.nav-tabs.nav-bordered .nav-item {
    margin-bottom: -1px;
}
.nav-tabs.nav-bordered li a {
    border: 0;
    padding: 0.625rem 1.25rem;
}
.nav-tabs.nav-bordered li a.active {
    border-bottom: 2px solid #536de6;
}
.pagination-rounded .page-link {
    border-radius: 30px !important;
    margin: 0 3px !important;
    border: none;
}
.popover-header {
    margin-top: 0;
}
@media print {
    .end-bar,
    .footer,
    .leftside-menu,
    .navbar-custom,
    .page-title-box {
        display: none;
    }
    .card-body,
    .content,
    .content-page,
    .end-bar,
    body {
        padding: 0;
        margin: 0;
    }
}
.progress-sm {
    height: 5px;
}
.progress-md {
    height: 8px;
}
.progress-lg {
    height: 12px;
}
.progress-xl {
    height: 15px;
}
body {
    padding-right: 0 !important;
    padding-left: 0 !important;
}
body.loading {
    visibility: hidden;
}
a,
button {
    outline: 0 !important;
}
label {
    font-weight: 600;
}
address.address-lg {
    line-height: 24px;
}
b,
strong {
    font-weight: 700;
}
.ribbon-box {
    position: relative;
}
.ribbon-box .ribbon {
    position: relative;
    clear: both;
    padding: 5px 12px;
    margin-bottom: 15px;
    -webkit-box-shadow: 2px 5px 10px rgba(50, 58, 70, 0.15);
    box-shadow: 2px 5px 10px rgba(50, 58, 70, 0.15);
    color: #fff;
    font-size: 13px;
    font-weight: 600;
}
.ribbon-box .ribbon:before {
    content: ' ';
    border-style: solid;
    border-width: 10px;
    display: block;
    position: absolute;
    bottom: -10px;
    left: 0;
    margin-bottom: -10px;
    z-index: -1;
}
.ribbon-box .ribbon.float-start {
    margin-left: -30px;
    border-radius: 0 3px 3px 0;
}
.ribbon-box .ribbon.float-end {
    margin-right: -30px;
    border-radius: 3px 0 0 3px;
}
.ribbon-box .ribbon.float-end:before {
    right: 0;
}
.ribbon-box .ribbon.float-center span {
    margin: 0 auto 20px auto;
}
.ribbon-box .ribbon-content {
    clear: both;
}
.ribbon-box .ribbon-primary {
    background: #536de6;
}
.ribbon-box .ribbon-primary:before {
    border-color: #3d5ae3 transparent transparent;
}
.ribbon-box .ribbon-secondary {
    background: #6c757d;
}
.ribbon-box .ribbon-secondary:before {
    border-color: #60686f transparent transparent;
}
.ribbon-box .ribbon-success {
    background: #10c469;
}
.ribbon-box .ribbon-success:before {
    border-color: #0eac5c transparent transparent;
}
.ribbon-box .ribbon-info {
    background: #35b8e0;
}
.ribbon-box .ribbon-info:before {
    border-color: #21afda transparent transparent;
}
.ribbon-box .ribbon-warning {
    background: #f9c851;
}
.ribbon-box .ribbon-warning:before {
    border-color: #f8c038 transparent transparent;
}
.ribbon-box .ribbon-danger {
    background: #ff5b5b;
}
.ribbon-box .ribbon-danger:before {
    border-color: #ff4242 transparent transparent;
}
.ribbon-box .ribbon-light {
    background: #eef2f7;
}
.ribbon-box .ribbon-light:before {
    border-color: #dde5ef transparent transparent;
}
.ribbon-box .ribbon-dark {
    background: #323a46;
}
.ribbon-box .ribbon-dark:before {
    border-color: #272e37 transparent transparent;
}
.ribbon-box .ribbon-two {
    position: absolute;
    left: -5px;
    top: -5px;
    z-index: 1;
    overflow: hidden;
    width: 75px;
    height: 75px;
    text-align: right;
}
.ribbon-box .ribbon-two span {
    font-size: 13px;
    color: #fff;
    text-align: center;
    line-height: 20px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    width: 100px;
    display: block;
    -webkit-box-shadow: 0 0 8px 0 rgba(50, 58, 70, 0.08), 0 1px 0 0 rgba(50, 58, 70, 0.03);
    box-shadow: 0 0 8px 0 rgba(50, 58, 70, 0.08), 0 1px 0 0 rgba(50, 58, 70, 0.03);
    position: absolute;
    top: 19px;
    left: -21px;
    font-weight: 600;
}
.ribbon-box .ribbon-two span:before {
    content: '';
    position: absolute;
    left: 0;
    top: 100%;
    z-index: -1;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
}
.ribbon-box .ribbon-two span:after {
    content: '';
    position: absolute;
    right: 0;
    top: 100%;
    z-index: -1;
    border-left: 3px solid transparent;
    border-bottom: 3px solid transparent;
}
.ribbon-box .ribbon-two-primary span {
    background: #536de6;
}
.ribbon-box .ribbon-two-primary span:before {
    border-left: 3px solid #3d5ae3;
    border-top: 3px solid #3d5ae3;
}
.ribbon-box .ribbon-two-primary span:after {
    border-right: 3px solid #3d5ae3;
    border-top: 3px solid #3d5ae3;
}
.ribbon-box .ribbon-two-secondary span {
    background: #6c757d;
}
.ribbon-box .ribbon-two-secondary span:before {
    border-left: 3px solid #60686f;
    border-top: 3px solid #60686f;
}
.ribbon-box .ribbon-two-secondary span:after {
    border-right: 3px solid #60686f;
    border-top: 3px solid #60686f;
}
.ribbon-box .ribbon-two-success span {
    background: #10c469;
}
.ribbon-box .ribbon-two-success span:before {
    border-left: 3px solid #0eac5c;
    border-top: 3px solid #0eac5c;
}
.ribbon-box .ribbon-two-success span:after {
    border-right: 3px solid #0eac5c;
    border-top: 3px solid #0eac5c;
}
.ribbon-box .ribbon-two-info span {
    background: #35b8e0;
}
.ribbon-box .ribbon-two-info span:before {
    border-left: 3px solid #21afda;
    border-top: 3px solid #21afda;
}
.ribbon-box .ribbon-two-info span:after {
    border-right: 3px solid #21afda;
    border-top: 3px solid #21afda;
}
.ribbon-box .ribbon-two-warning span {
    background: #f9c851;
}
.ribbon-box .ribbon-two-warning span:before {
    border-left: 3px solid #f8c038;
    border-top: 3px solid #f8c038;
}
.ribbon-box .ribbon-two-warning span:after {
    border-right: 3px solid #f8c038;
    border-top: 3px solid #f8c038;
}
.ribbon-box .ribbon-two-danger span {
    background: #ff5b5b;
}
.ribbon-box .ribbon-two-danger span:before {
    border-left: 3px solid #ff4242;
    border-top: 3px solid #ff4242;
}
.ribbon-box .ribbon-two-danger span:after {
    border-right: 3px solid #ff4242;
    border-top: 3px solid #ff4242;
}
.ribbon-box .ribbon-two-light span {
    background: #eef2f7;
}
.ribbon-box .ribbon-two-light span:before {
    border-left: 3px solid #dde5ef;
    border-top: 3px solid #dde5ef;
}
.ribbon-box .ribbon-two-light span:after {
    border-right: 3px solid #dde5ef;
    border-top: 3px solid #dde5ef;
}
.ribbon-box .ribbon-two-dark span {
    background: #323a46;
}
.ribbon-box .ribbon-two-dark span:before {
    border-left: 3px solid #272e37;
    border-top: 3px solid #272e37;
}
.ribbon-box .ribbon-two-dark span:after {
    border-right: 3px solid #272e37;
    border-top: 3px solid #272e37;
}
input[data-switch] {
    display: none;
}
input[data-switch] + label {
    width: 56px;
    height: 24px;
    background-color: #f1f3fa;
    background-image: none;
    border-radius: 2rem;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    position: relative;
    -webkit-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
}
input[data-switch] + label:before {
    color: #323a46;
    content: attr(data-off-label);
    display: block;
    font-family: inherit;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 24px;
    position: absolute;
    right: 3px;
    margin: 0 0.21667rem;
    top: 0;
    text-align: center;
    min-width: 1.66667rem;
    overflow: hidden;
    -webkit-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
}
input[data-switch] + label:after {
    content: '';
    position: absolute;
    left: 4px;
    background-color: #adb5bd;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 2rem;
    height: 18px;
    width: 18px;
    top: 3px;
    -webkit-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
}
input[data-switch]:checked + label {
    background-color: #536de6;
}
input[data-switch]:checked + label:before {
    color: #fff;
    content: attr(data-on-label);
    right: auto;
    left: 4px;
}
input[data-switch]:checked + label:after {
    left: 34px;
    background-color: #f1f3fa;
}
input[data-switch='bool'] + label {
    background-color: #ff5b5b;
}
input:disabled + label {
    opacity: 0.5;
    cursor: default;
}
input[data-switch='bool'] + label:before,
input[data-switch='bool']:checked + label:before {
    color: #fff !important;
}
input[data-switch='bool'] + label:after {
    background-color: #f1f3fa;
}
input[data-switch='primary']:checked + label {
    background-color: #536de6;
}
input[data-switch='secondary']:checked + label {
    background-color: #6c757d;
}
input[data-switch='success']:checked + label {
    background-color: #10c469;
}
input[data-switch='info']:checked + label {
    background-color: #35b8e0;
}
input[data-switch='warning']:checked + label {
    background-color: #f9c851;
}
input[data-switch='danger']:checked + label {
    background-color: #ff5b5b;
}
input[data-switch='light']:checked + label {
    background-color: #eef2f7;
}
input[data-switch='dark']:checked + label {
    background-color: #323a46;
}
.table-centered td,
.table-centered th {
    vertical-align: middle !important;
}
.table .table-user img {
    height: 30px;
    width: 30px;
}
.table .action-icon {
    color: #98a6ad;
    font-size: 1.2rem;
    display: inline-block;
    padding: 0 3px;
}
.table .action-icon:hover {
    color: #6c757d;
}
.table-nowrap td,
.table-nowrap th {
    white-space: nowrap;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 10px 0;
    font-weight: 700;
}
.font-10 {
    font-size: 10px !important;
}
.font-11 {
    font-size: 11px !important;
}
.font-12 {
    font-size: 12px !important;
}
.font-13 {
    font-size: 13px !important;
}
.font-14 {
    font-size: 14px !important;
}
.font-15 {
    font-size: 15px !important;
}
.font-16 {
    font-size: 16px !important;
}
.font-18 {
    font-size: 18px !important;
}
.font-20 {
    font-size: 20px !important;
}
.font-22 {
    font-size: 22px !important;
}
.font-24 {
    font-size: 24px !important;
}
.bg-primary-lighten {
    background-color: rgba(83, 109, 230, 0.25) !important;
}
.bg-secondary-lighten {
    background-color: rgba(108, 117, 125, 0.25) !important;
}
.bg-success-lighten {
    background-color: rgba(16, 196, 105, 0.25) !important;
}
.bg-info-lighten {
    background-color: rgba(53, 184, 224, 0.25) !important;
}
.bg-warning-lighten {
    background-color: rgba(249, 200, 81, 0.25) !important;
}
.bg-danger-lighten {
    background-color: rgba(255, 91, 91, 0.25) !important;
}
.bg-light-lighten {
    background-color: rgba(238, 242, 247, 0.25) !important;
}
.bg-dark-lighten {
    background-color: rgba(50, 58, 70, 0.25) !important;
}
.fw-semibold {
    font-weight: 600 !important;
}
.progress-w-percent {
    min-height: 20px;
    margin-bottom: 20px;
}
.progress-w-percent .progress {
    width: calc(100% - 50px);
    float: left;
    margin-top: 8px;
}
.progress-w-percent .progress-value {
    width: 40px;
    float: right;
    text-align: right;
    line-height: 20px;
}
.widget-flat {
    position: relative;
    overflow: hidden;
}
@media (min-width: 1200px) and (max-width: 1500px) {
    .widget-flat i.widget-icon {
        display: none;
    }
}
.widget-icon {
    color: #536de6;
    font-size: 20px;
    background-color: rgba(83, 109, 230, 0.25);
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 40px;
    border-radius: 3px;
    display: inline-block;
}
.dash-item-overlay {
    position: absolute;
    text-align: left;
    left: 8%;
    max-width: 350px;
    padding: 20px;
    z-index: 1;
}
.chart-content-bg {
    background-color: #f9f9fd;
}
.chart-content-border {
    border: 1px solid #eef2f7;
}
.chart-widget-list p {
    border-bottom: 1px solid #f1f3fa;
    margin-bottom: 0.5rem;
    padding-bottom: 0.5rem;
}
.timeline-alt {
    padding: 20px 0;
    position: relative;
}
.timeline-alt .timeline-item {
    position: relative;
}
.timeline-alt .timeline-item:before {
    background-color: #f1f3fa;
    bottom: 0;
    content: '';
    left: 9px;
    position: absolute;
    top: 20px;
    width: 2px;
    z-index: 0;
}
.timeline-alt .timeline-item .timeline-icon {
    float: left;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 2px solid transparent;
    font-size: 12px;
    text-align: center;
    line-height: 16px;
    background-color: #fff;
}
.timeline-alt .timeline-item .timeline-item-info {
    margin-left: 30px;
}
.inbox-widget .inbox-item {
    border-bottom: 1px solid #fff;
    overflow: hidden;
    padding: 0.625rem 0;
    position: relative;
}
.inbox-widget .inbox-item:last-of-type {
    border-bottom: none;
}
.inbox-widget .inbox-item .inbox-item-img {
    display: block;
    float: left;
    margin-right: 15px;
    width: 40px;
}
.inbox-widget .inbox-item .inbox-item-img img {
    width: 40px;
}
.inbox-widget .inbox-item .inbox-item-author {
    color: #343a40;
    display: block;
    margin-bottom: 3px;
}
.inbox-widget .inbox-item .inbox-item-text {
    color: #adb5bd;
    display: block;
    font-size: 0.8125rem;
    margin: 0;
}
.inbox-widget .inbox-item .inbox-item-date {
    color: #98a6ad;
    font-size: 0.6875rem;
    position: absolute;
    right: 5px;
    top: 10px;
}
.tilebox-one i {
    position: absolute;
    right: 1.5rem;
    font-size: 2rem;
    opacity: 0.3;
}
.toll-free-box i {
    position: absolute;
    left: 0;
    bottom: -15px;
    font-size: 4rem;
    opacity: 0.4;
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);
}
.cta-box {
    background-size: cover;
}
.cta-box .cta-box-title {
    font-size: 20px;
    line-height: 30px;
}
.conversation-list {
    list-style: none;
    padding: 0 15px;
}
.conversation-list li {
    margin-bottom: 20px;
}
.conversation-list li .conversation-actions {
    float: right;
    display: none;
}
.conversation-list li:hover .conversation-actions {
    display: block;
}
.conversation-list .chat-avatar {
    float: left;
    text-align: center;
    width: 42px;
}
.conversation-list .chat-avatar img {
    border-radius: 100%;
    width: 100%;
}
.conversation-list .chat-avatar i {
    font-size: 12px;
    font-style: normal;
}
.conversation-list .ctext-wrap {
    background: #f1f3fa;
    border-radius: 3px;
    display: inline-block;
    padding: 12px;
    position: relative;
}
.conversation-list .ctext-wrap i {
    display: block;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    position: relative;
}
.conversation-list .ctext-wrap p {
    margin: 0;
    padding-top: 3px;
}
.conversation-list .ctext-wrap:after {
    left: -11px;
    top: 0;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-top-color: #f1f3fa;
    border-width: 6px;
    margin-right: -1px;
    border-right-color: #f1f3fa;
}
.conversation-list .conversation-text {
    float: left;
    font-size: 13px;
    margin-left: 12px;
    width: 70%;
}
.conversation-list .odd .chat-avatar {
    float: right !important;
}
.conversation-list .odd .conversation-text {
    float: right !important;
    margin-right: 12px;
    text-align: right;
    width: 70% !important;
}
.conversation-list .odd .ctext-wrap {
    background-color: #fef5e4;
}
.conversation-list .odd .ctext-wrap:after {
    border-color: transparent;
    border-left-color: #fef5e4;
    border-top-color: #fef5e4;
    right: -10px;
    left: auto;
}
.conversation-list .odd .conversation-actions {
    float: left;
}
.calendar-widget .datepicker-inline,
.calendar-widget table {
    width: 100%;
}
.calendar-widget .datepicker-inline tr td,
.calendar-widget .datepicker-inline tr td.active.day,
.calendar-widget .datepicker-inline tr td.today.day,
.calendar-widget .datepicker-inline tr th,
.calendar-widget table tr td,
.calendar-widget table tr td.active.day,
.calendar-widget table tr td.today.day,
.calendar-widget table tr th {
    background-color: transparent !important;
}
.calendar-widget .datepicker-inline tr td.active.day,
.calendar-widget .datepicker-inline tr td.today.day,
.calendar-widget table tr td.active.day,
.calendar-widget table tr td.today.day {
    color: #ff5b5b !important;
    text-shadow: none;
    font-weight: 700;
}
.calendar-widget .datepicker-inline tr td.active.day:hover,
.calendar-widget .datepicker-inline tr td.today.day:hover,
.calendar-widget table tr td.active.day:hover,
.calendar-widget table tr td.today.day:hover {
    background-color: transparent !important;
}
.calendar-widget .datepicker-inline td,
.calendar-widget .datepicker-inline th,
.calendar-widget table td,
.calendar-widget table th {
    height: 43px;
}
.calendar-widget .datepicker-inline .datepicker-switch,
.calendar-widget .datepicker-inline .next,
.calendar-widget .datepicker-inline .prev,
.calendar-widget table .datepicker-switch,
.calendar-widget table .next,
.calendar-widget table .prev {
    font-size: 1.1rem;
    background-color: rgba(83, 109, 230, 0.1) !important;
    border-radius: 0;
    color: #536de6;
}
.social-list-item {
    height: 2rem;
    width: 2rem;
    line-height: calc(2rem - 2px);
    display: block;
    border: 2px solid #adb5bd;
    border-radius: 50%;
    color: #adb5bd;
}
.social-list-item:hover {
    color: #98a6ad;
    border-color: #98a6ad;
}
.horizontal-steps {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
}
.horizontal-steps:before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 0.2em;
    background-color: #eef2f7;
}
.horizontal-steps .process-line {
    display: block;
    position: absolute;
    width: 50%;
    height: 0.2em;
    background-color: #536de6;
}
.horizontal-steps .horizontal-steps-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
}
.horizontal-steps .horizontal-steps-content .step-item {
    display: block;
    position: relative;
    bottom: calc(100% + 1em);
    height: 8px;
    width: 8px;
    margin: 0 2em;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    color: #536de6;
    background-color: currentColor;
    border: 0.25em solid #fafbfe;
    border-radius: 50%;
    z-index: 5;
}
.horizontal-steps .horizontal-steps-content .step-item:first-child {
    margin-left: 0;
}
.horizontal-steps .horizontal-steps-content .step-item:last-child {
    margin-right: 0;
    color: #10c469;
}
.horizontal-steps .horizontal-steps-content .step-item span {
    position: absolute;
    top: calc(100% + 1em);
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    white-space: nowrap;
    color: #adb5bd;
}
.horizontal-steps .horizontal-steps-content .step-item.current:before {
    content: '';
    display: block;
    position: absolute;
    top: 47.5%;
    left: 51%;
    padding: 1em;
    background-color: currentColor;
    border-radius: 50%;
    opacity: 0;
    z-index: -1;
    -webkit-animation-name: animation-steps-current;
    animation-name: animation-steps-current;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
}
.horizontal-steps .horizontal-steps-content .step-item.current span {
    color: #536de6;
}
@-webkit-keyframes animation-steps-current {
    from {
        -webkit-transform: translate(-50%, -50%) scale(0);
        transform: translate(-50%, -50%) scale(0);
        opacity: 1;
    }
    to {
        -webkit-transform: translate(-50%, -50%) scale(1);
        transform: translate(-50%, -50%) scale(1);
        opacity: 0;
    }
}
@keyframes animation-steps-current {
    from {
        -webkit-transform: translate(-50%, -50%) scale(0);
        transform: translate(-50%, -50%) scale(0);
        opacity: 1;
    }
    to {
        -webkit-transform: translate(-50%, -50%) scale(1);
        transform: translate(-50%, -50%) scale(1);
        opacity: 0;
    }
}
@media (max-width: 767.98px) {
    .horizontal-steps .horizontal-steps-content .step-item span {
        white-space: inherit;
    }
}
#preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #f1f3fa;
    z-index: 9999;
}
#status {
    width: 80px;
    height: 80px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -40px 0 0 -40px;
}
@-webkit-keyframes bouncing-loader {
    to {
        opacity: 0.1;
        -webkit-transform: translate3d(0, -16px, 0);
        transform: translate3d(0, -16px, 0);
    }
}
@keyframes bouncing-loader {
    to {
        opacity: 0.1;
        -webkit-transform: translate3d(0, -16px, 0);
        transform: translate3d(0, -16px, 0);
    }
}
.bouncing-loader {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.bouncing-loader > div {
    width: 13px;
    height: 13px;
    margin: 32px 3px;
    background: #536de6;
    border-radius: 50%;
    -webkit-animation: bouncing-loader 0.6s infinite alternate;
    animation: bouncing-loader 0.6s infinite alternate;
}
.bouncing-loader > div:nth-child(2) {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
    background: #ff5b5b;
}
.bouncing-loader > div:nth-child(3) {
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
    background: #10c469;
}
.hero-section {
    position: relative;
    padding: 80px 0 120px 0;
}
.hero-section:after {
    content: ' ';
    background-image: -webkit-gradient(linear, left top, left bottom, from(#6379c3), to(#546ee5));
    background-image: linear-gradient(to bottom, #6379c3, #546ee5);
    position: absolute;
    top: -400px;
    right: 0;
    bottom: 0;
    z-index: -1;
    width: 100%;
    border-radius: 0;
    -webkit-transform: skewY(-3deg);
    transform: skewY(-3deg);
}
.hero-section .hero-title {
    line-height: 42px;
}
body.authentication-bg {
    background-size: cover;
    background-position: center;
}
body.authentication-bg .account-pages {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    min-height: 100vh;
}
.auth-fluid-pages.enlarged,
.authentication-bg.enlarged,
body.auth-fluid-pages[data-leftbar-compact-mode='condensed'] {
    min-height: 100px;
}
.logout-icon {
    width: 140px;
}
.auth-fluid {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: 100vh;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    background-size: cover;
}
.auth-fluid .auth-fluid-form-box {
    max-width: 480px;
    border-radius: 0;
    z-index: 2;
    padding: 3rem 2rem;
    background-color: #fff;
    position: relative;
    width: 100%;
}
.auth-fluid .auth-fluid-left,
.auth-fluid .auth-fluid-right {
    padding: 6rem 3rem;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    position: relative;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.3);
}
.auth-brand {
    margin-bottom: 2rem;
}
.auth-brand .logo-dark {
    display: block;
}
.auth-brand .logo-light {
    display: none;
}
.auth-user-testimonial {
    position: absolute;
    margin: 0 auto;
    padding: 0 1.75rem;
    bottom: 3rem;
    left: 0;
    right: 0;
}
.auth-user-testimonial p.lead {
    font-size: 1.125rem;
    margin: 0 auto 20px auto;
    max-width: 700px;
}
@media (min-width: 992px) {
    .auth-brand {
        position: absolute;
        top: 3rem;
    }
}
@media (max-width: 991.98px) {
    .auth-fluid {
        display: block;
    }
    .auth-fluid .auth-fluid-form-box {
        max-width: 100%;
        min-height: 100vh;
    }
    .auth-fluid .auth-fluid-right {
        display: none;
    }
}
.button-list {
    margin-left: -8px;
    margin-bottom: -12px;
}
.button-list .btn {
    margin-bottom: 12px;
    margin-left: 8px;
}
.scrollspy-example {
    position: relative;
    height: 200px;
    margin-top: 0.5rem;
    overflow: auto;
}
.grid-structure .grid-container {
    background-color: #f1f3fa;
    margin-bottom: 10px;
    font-size: 0.8rem;
    font-weight: 600;
    padding: 10px 20px;
}
.icons-list-demo div {
    cursor: pointer;
    line-height: 45px;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
}
.icons-list-demo div p {
    margin-bottom: 0;
    line-height: inherit;
}
.icons-list-demo i {
    text-align: center;
    vertical-align: middle;
    font-size: 22px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    margin-right: 12px;
    border-radius: 3px;
    display: inline-block;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
}
.icons-list-demo .col-md-4 {
    border-radius: 3px;
    -moz-border-radius: 3px;
    background-clip: padding-box;
    margin-bottom: 10px;
}
.icons-list-demo .col-md-4:hover,
.icons-list-demo .col-md-4:hover i {
    color: #536de6;
}
.text-error {
    color: #536de6;
    text-shadow: rgba(83, 109, 230, 0.3) 5px 1px, rgba(83, 109, 230, 0.2) 10px 3px;
    font-size: 5.25rem;
    line-height: 5.625rem;
}
.faq-question-q-box {
    height: 30px;
    width: 30px;
    color: #536de6;
    background-color: rgba(83, 109, 230, 0.25);
    -webkit-box-shadow: 0 0 45px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 0 45px 0 rgba(0, 0, 0, 0.12);
    text-align: center;
    border-radius: 50%;
    float: left;
    font-weight: 700;
    line-height: 30px;
}
.faq-question {
    margin-top: 0;
    margin-left: 50px;
    font-weight: 600;
    font-size: 16px;
    color: #323a46;
}
.faq-answer {
    margin-left: 50px;
}
.maintenance-icon {
    font-size: 22px;
    -webkit-box-shadow: 0 0 45px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 0 45px 0 rgba(0, 0, 0, 0.12);
    height: 60px;
    display: inline-block;
    width: 60px;
    line-height: 58px;
    border-radius: 50%;
}
.board {
    display: block;
    white-space: nowrap;
    overflow-x: auto;
}
.tasks {
    display: inline-block;
    width: 22rem;
    padding: 0 1rem 1rem 1rem;
    border: 1px solid #eef2f7;
    vertical-align: top;
    margin-bottom: 24px;
    border-radius: 0.25rem;
}
.tasks.tasks:not(:last-child) {
    margin-right: 1.25rem;
}
.tasks .card {
    white-space: normal;
    margin-top: 1rem;
}
.tasks .task-header {
    background-color: #f1f3fa;
    padding: 1rem;
    margin: 0 -1rem;
}
.task-list-items {
    min-height: 100px;
    position: relative;
}
.task-list-items:before {
    content: 'No Tasks';
    position: absolute;
    line-height: 110px;
    width: 100%;
    text-align: center;
    font-weight: 600;
}
.task-modal-content .form-control-light {
    background-color: #f7f9fb !important;
    border-color: #f7f9fb !important;
}
.gantt-task-details {
    min-width: 220px;
}
.page-aside-left {
    width: 240px;
    float: left;
    padding: 0 20px 20px 10px;
    position: relative;
}
.page-aside-left:before {
    content: '';
    background-color: #fafbfe;
    width: 5px;
    position: absolute;
    right: -15px;
    height: 100%;
    bottom: -1.5rem;
}
.page-aside-right {
    margin: -1.5rem 0 -1.5rem 250px;
    border-left: 5px solid #fafbfe;
    padding: 1.5rem 0 1.5rem 25px;
}
.email-list {
    display: block;
    padding-left: 0;
    overflow: hidden;
}
.email-list > li {
    position: relative;
    display: block;
    height: 51px;
    line-height: 50px;
    cursor: default;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
}
.email-list > li a {
    color: #6c757d;
}
.email-list > li a:hover {
    color: #343a40;
}
.email-list > li .col-mail {
    float: left;
    position: relative;
}
.email-list > li .email-sender-info {
    width: 320px;
}
.email-list > li .email-sender-info .checkbox-wrapper-mail,
.email-list > li .email-sender-info .star-toggle {
    display: block;
    float: left;
}
.email-list > li .email-sender-info .checkbox-wrapper-mail {
    margin: 15px 10px 0 20px;
    cursor: pointer;
    height: 20px;
    width: 20px;
}
.email-list > li .email-sender-info .star-toggle {
    color: #adb5bd;
    margin-left: 10px;
    font-size: 18px;
}
.email-list > li .email-sender-info .email-title {
    position: absolute;
    top: 0;
    left: 100px;
    right: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-bottom: 0;
    line-height: 50px;
}
.email-list > li .email-content {
    position: absolute;
    top: 0;
    left: 320px;
    right: 0;
    bottom: 0;
}
.email-list > li .email-content .email-date,
.email-list > li .email-content .email-subject {
    position: absolute;
    top: 0;
}
.email-list > li .email-content .email-subject {
    left: 0;
    right: 110px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.email-list > li .email-content .email-date {
    right: 0;
    width: 100px;
    text-align: right;
    padding-left: 10px;
}
.email-list > li.active,
.email-list > li.mail-selected {
    background: #f1f3fa;
    -webkit-transition-duration: 50ms;
    transition-duration: 50ms;
}
.email-list > li.unread a {
    font-weight: 600;
    color: #272e37;
}
.email-list > li .email-action-icons {
    opacity: 0;
}
.email-list > li .email-action-icons ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: absolute;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    right: -180px;
}
.email-list > li .email-action-icons ul li {
    margin: 0 10px;
}
.email-list > li .email-action-icons ul .email-action-icons-item {
    font-size: 20px;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}
.email-list > li .email-action-icons ul .email-action-icons-item:hover {
    color: #ff5b5b;
}
.email-list > li:hover {
    background: #f1f3fa;
    -webkit-transition-duration: 50ms;
    transition-duration: 50ms;
}
.email-list > li:hover .email-action-icons {
    opacity: 1;
}
.email-list > li:hover .email-action-icons ul {
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    right: 10px;
}
.email-list > li:hover .email-content .email-date {
    opacity: 0;
}
.email-list > li:hover .email-content .email-subject {
    right: 180px;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}
.email-menu-list a {
    color: #6c757d;
    padding: 12px 5px;
    display: block;
    font-size: 15px;
}
.email-menu-list a:hover {
    color: #343a40;
}
.email-menu-list a .badge {
    margin-top: 3px;
}
.labels-list a {
    padding: 7px 5px;
}
.write-mdg-box .CodeMirror {
    height: 150px;
}
@media (max-width: 648px) {
    .page-aside-left {
        width: 100%;
        float: none;
        padding: 0 10px 20px 10px;
    }
    .page-aside-left:before {
        width: 0;
    }
    .page-aside-right {
        margin-left: 0;
        border: 0;
        padding-left: 0;
    }
    .email-list li .email-sender-info .checkbox-wrapper-mail {
        margin-left: 0;
    }
}
@media (max-width: 520px) {
    .page-aside-right > .btn-group {
        margin-bottom: 10px;
    }
    .email-list li .email-sender-info {
        width: 150px;
    }
    .email-list li .email-sender-info .email-title {
        left: 80px;
    }
    .email-list li .email-content {
        display: none;
    }
}
.timeline {
    margin-bottom: 50px;
    position: relative;
}
.timeline:before {
    background-color: #dee2e6;
    bottom: 0;
    content: '';
    left: 50%;
    position: absolute;
    top: 30px;
    width: 2px;
    z-index: 0;
}
.timeline-show {
    position: relative;
}
.timeline-show .time-show-name {
    display: inline-block;
    border-radius: 4px;
    background-color: #eef2f7;
    padding: 7px 15px;
}
.timeline-box {
    background-color: #fff;
    -webkit-box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
    box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
    display: block;
    margin: 15px 0;
    position: relative;
    padding: 1.5rem;
    border-radius: 0.25rem;
}
.timeline-album {
    margin-top: 12px;
}
.timeline-album a {
    display: inline-block;
    margin-right: 5px;
}
.timeline-album img {
    height: 36px;
    width: auto;
    border-radius: 3px;
}
@media (min-width: 768px) {
    .timeline .timeline-box {
        margin-left: 45px;
    }
    .timeline .timeline-icon {
        background: #dee2e6;
        border-radius: 50%;
        display: block;
        height: 24px;
        left: -56px;
        margin-top: -12px;
        position: absolute;
        text-align: center;
        top: 50%;
        width: 24px;
    }
    .timeline .timeline-icon i {
        color: #98a6ad;
        font-size: 1rem;
        vertical-align: middle;
    }
    .timeline .timeline-desk {
        display: table-cell;
        vertical-align: top;
        width: 50%;
    }
    .timeline-lg-item {
        display: table-row;
    }
    .timeline-lg-item:before {
        content: '';
        display: block;
        width: 50%;
    }
    .timeline-lg-item .timeline-desk .arrow {
        border-bottom: 12px solid transparent;
        border-right: 12px solid #fff !important;
        border-top: 12px solid transparent;
        display: block;
        height: 0;
        left: -12px;
        margin-top: -12px;
        position: absolute;
        top: 50%;
        width: 0;
    }
    .timeline-lg-item.timeline-item-left:after {
        content: '';
        display: block;
        width: 50%;
    }
    .timeline-lg-item.timeline-item-left .timeline-desk .arrow-alt {
        border-bottom: 12px solid transparent;
        border-left: 12px solid #fff !important;
        border-top: 12px solid transparent;
        display: block;
        height: 0;
        left: auto;
        margin-top: -12px;
        position: absolute;
        right: -12px;
        top: 50%;
        width: 0;
    }
    .timeline-lg-item.timeline-item-left .timeline-desk .album {
        float: right;
        margin-top: 20px;
    }
    .timeline-lg-item.timeline-item-left .timeline-desk .album a {
        float: right;
        margin-left: 5px;
    }
    .timeline-lg-item.timeline-item-left .timeline-icon {
        left: auto;
        right: -58px;
    }
    .timeline-lg-item.timeline-item-left:before {
        display: none;
    }
    .timeline-lg-item.timeline-item-left .timeline-box {
        margin-right: 45px;
        margin-left: 0;
    }
}
@media (max-width: 767.98px) {
    .timeline .timeline-icon {
        display: none;
    }
}
.daterangepicker {
    position: absolute;
    color: inherit;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #ddd;
    width: 278px;
    max-width: none;
    padding: 0;
    margin-top: 7px;
    top: 100px;
    left: 20px;
    z-index: 3001;
    display: none;
    font-family: arial;
    font-size: 15px;
    line-height: 1em;
}
.daterangepicker:after,
.daterangepicker:before {
    position: absolute;
    display: inline-block;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    content: '';
}
.daterangepicker:before {
    top: -7px;
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
    border-bottom: 7px solid #ccc;
}
.daterangepicker:after {
    top: -6px;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #fff;
    border-left: 6px solid transparent;
}
.daterangepicker.opensleft:before {
    right: 9px;
}
.daterangepicker.opensleft:after {
    right: 10px;
}
.daterangepicker.openscenter:before {
    left: 0;
    right: 0;
    width: 0;
    margin-left: auto;
    margin-right: auto;
}
.daterangepicker.openscenter:after {
    left: 0;
    right: 0;
    width: 0;
    margin-left: auto;
    margin-right: auto;
}
.daterangepicker.opensright:before {
    left: 9px;
}
.daterangepicker.opensright:after {
    left: 10px;
}
.daterangepicker.drop-up {
    margin-top: -7px;
}
.daterangepicker.drop-up:before {
    top: initial;
    bottom: -7px;
    border-bottom: initial;
    border-top: 7px solid #ccc;
}
.daterangepicker.drop-up:after {
    top: initial;
    bottom: -6px;
    border-bottom: initial;
    border-top: 6px solid #fff;
}
.daterangepicker.single .daterangepicker .ranges,
.daterangepicker.single .drp-calendar {
    float: none;
}
.daterangepicker.single .drp-selected {
    display: none;
}
.daterangepicker.show-calendar .drp-calendar {
    display: block;
}
.daterangepicker.show-calendar .drp-buttons {
    display: block;
}
.daterangepicker.auto-apply .drp-buttons {
    display: none;
}
.daterangepicker .drp-calendar {
    display: none;
    max-width: 270px;
}
.daterangepicker .drp-calendar.left {
    padding: 8px 0 8px 8px;
}
.daterangepicker .drp-calendar.right {
    padding: 8px;
}
.daterangepicker .drp-calendar.single .calendar-table {
    border: none;
}
.daterangepicker .calendar-table .next span,
.daterangepicker .calendar-table .prev span {
    color: #fff;
    border: solid #000;
    border-width: 0 2px 2px 0;
    border-radius: 0;
    display: inline-block;
    padding: 3px;
}
.daterangepicker .calendar-table .next span {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}
.daterangepicker .calendar-table .prev span {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}
.daterangepicker .calendar-table td,
.daterangepicker .calendar-table th {
    white-space: nowrap;
    text-align: center;
    vertical-align: middle;
    min-width: 32px;
    width: 32px;
    height: 24px;
    line-height: 24px;
    font-size: 12px;
    border-radius: 4px;
    border: 1px solid transparent;
    white-space: nowrap;
    cursor: pointer;
}
.daterangepicker .calendar-table {
    border: 1px solid #fff;
    border-radius: 4px;
    background-color: #fff;
}
.daterangepicker .calendar-table table {
    width: 100%;
    margin: 0;
    border-spacing: 0;
    border-collapse: collapse;
}
.daterangepicker td.available:hover,
.daterangepicker th.available:hover {
    background-color: #eee;
    border-color: transparent;
    color: inherit;
}
.daterangepicker td.week,
.daterangepicker th.week {
    font-size: 80%;
    color: #ccc;
}
.daterangepicker td.off,
.daterangepicker td.off.end-date,
.daterangepicker td.off.in-range,
.daterangepicker td.off.start-date {
    background-color: #fff;
    border-color: transparent;
    color: #999;
}
.daterangepicker td.in-range {
    background-color: #ebf4f8;
    border-color: transparent;
    color: #000;
    border-radius: 0;
}
.daterangepicker td.start-date {
    border-radius: 4px 0 0 4px;
}
.daterangepicker td.end-date {
    border-radius: 0 4px 4px 0;
}
.daterangepicker td.start-date.end-date {
    border-radius: 4px;
}
.daterangepicker td.active,
.daterangepicker td.active:hover {
    background-color: #357ebd;
    border-color: transparent;
    color: #fff;
}
.daterangepicker th.month {
    width: auto;
}
.daterangepicker option.disabled,
.daterangepicker td.disabled {
    color: #999;
    cursor: not-allowed;
    text-decoration: line-through;
}
.daterangepicker select.monthselect,
.daterangepicker select.yearselect {
    font-size: 12px;
    padding: 1px;
    height: auto;
    margin: 0;
    cursor: default;
}
.daterangepicker select.monthselect {
    margin-right: 2%;
    width: 56%;
}
.daterangepicker select.yearselect {
    width: 40%;
}
.daterangepicker select.ampmselect,
.daterangepicker select.hourselect,
.daterangepicker select.minuteselect,
.daterangepicker select.secondselect {
    width: 50px;
    margin: 0 auto;
    background: #eee;
    border: 1px solid #eee;
    padding: 2px;
    outline: 0;
    font-size: 12px;
}
.daterangepicker .calendar-time {
    text-align: center;
    margin: 4px auto 0 auto;
    line-height: 30px;
    position: relative;
}
.daterangepicker .calendar-time select.disabled {
    color: #ccc;
    cursor: not-allowed;
}
.daterangepicker .drp-buttons {
    clear: both;
    text-align: right;
    padding: 8px;
    border-top: 1px solid #ddd;
    display: none;
    line-height: 12px;
    vertical-align: middle;
}
.daterangepicker .drp-selected {
    display: inline-block;
    font-size: 12px;
    padding-right: 8px;
}
.daterangepicker .drp-buttons .btn {
    margin-left: 8px;
    font-size: 12px;
    font-weight: 700;
    padding: 4px 8px;
}
.daterangepicker.show-ranges.single.rtl .drp-calendar.left {
    border-right: 1px solid #ddd;
}
.daterangepicker.show-ranges.single.ltr .drp-calendar.left {
    border-left: 1px solid #ddd;
}
.daterangepicker.show-ranges.rtl .drp-calendar.right {
    border-right: 1px solid #ddd;
}
.daterangepicker.show-ranges.ltr .drp-calendar.left {
    border-left: 1px solid #ddd;
}
.daterangepicker .ranges {
    float: none;
    text-align: left;
    margin: 0;
}
.daterangepicker.show-calendar .ranges {
    margin-top: 8px;
}
.daterangepicker .ranges ul {
    list-style: none;
    margin: 0 auto;
    padding: 0;
    width: 100%;
}
.daterangepicker .ranges li {
    font-size: 12px;
    padding: 8px 12px;
    cursor: pointer;
}
.daterangepicker .ranges li:hover {
    background-color: #eee;
}
.daterangepicker .ranges li.active {
    background-color: #08c;
    color: #fff;
}
@media (min-width: 564px) {
    .daterangepicker {
        width: auto;
    }
    .daterangepicker .ranges ul {
        width: 140px;
    }
    .daterangepicker.single .ranges ul {
        width: 100%;
    }
    .daterangepicker.single .drp-calendar.left {
        clear: none;
    }
    .daterangepicker.single .drp-calendar,
    .daterangepicker.single .ranges {
        float: left;
    }
    .daterangepicker {
        direction: ltr;
        text-align: left;
    }
    .daterangepicker .drp-calendar.left {
        clear: left;
        margin-right: 0;
    }
    .daterangepicker .drp-calendar.left .calendar-table {
        border-right: none;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .daterangepicker .drp-calendar.right {
        margin-left: 0;
    }
    .daterangepicker .drp-calendar.right .calendar-table {
        border-left: none;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    .daterangepicker .drp-calendar.left .calendar-table {
        padding-right: 8px;
    }
    .daterangepicker .drp-calendar,
    .daterangepicker .ranges {
        float: left;
    }
}
@media (min-width: 730px) {
    .daterangepicker .ranges {
        width: auto;
    }
    .daterangepicker .ranges {
        float: left;
    }
    .daterangepicker.rtl .ranges {
        float: right;
    }
    .daterangepicker .drp-calendar.left {
        clear: none !important;
    }
} /*!
 * Datepicker for Bootstrap v1.9.0 (https://github.com/uxsolutions/bootstrap-datepicker)
 *
 * Licensed under the Apache License v2.0 (http://www.apache.org/licenses/LICENSE-2.0)
 */
.datepicker {
    padding: 4px;
    border-radius: 4px;
    direction: ltr;
}
.datepicker-inline {
    width: 220px;
}
.datepicker-rtl {
    direction: rtl;
}
.datepicker-rtl.dropdown-menu {
    left: auto;
}
.datepicker-rtl table tr td span {
    float: right;
}
.datepicker-dropdown {
    top: 0;
    left: 0;
}
.datepicker-dropdown:before {
    content: '';
    display: inline-block;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #999;
    border-top: 0;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    position: absolute;
}
.datepicker-dropdown:after {
    content: '';
    display: inline-block;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #fff;
    border-top: 0;
    position: absolute;
}
.datepicker-dropdown.datepicker-orient-left:before {
    left: 6px;
}
.datepicker-dropdown.datepicker-orient-left:after {
    left: 7px;
}
.datepicker-dropdown.datepicker-orient-right:before {
    right: 6px;
}
.datepicker-dropdown.datepicker-orient-right:after {
    right: 7px;
}
.datepicker-dropdown.datepicker-orient-bottom:before {
    top: -7px;
}
.datepicker-dropdown.datepicker-orient-bottom:after {
    top: -6px;
}
.datepicker-dropdown.datepicker-orient-top:before {
    bottom: -7px;
    border-bottom: 0;
    border-top: 7px solid #999;
}
.datepicker-dropdown.datepicker-orient-top:after {
    bottom: -6px;
    border-bottom: 0;
    border-top: 6px solid #fff;
}
.datepicker table {
    margin: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.datepicker td,
.datepicker th {
    text-align: center;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    border: none;
}
.table-striped .datepicker table tr td,
.table-striped .datepicker table tr th {
    background-color: transparent;
}
.datepicker table tr td.day.focused,
.datepicker table tr td.day:hover {
    background: #eee;
    cursor: pointer;
}
.datepicker table tr td.new,
.datepicker table tr td.old {
    color: #999;
}
.datepicker table tr td.disabled,
.datepicker table tr td.disabled:hover {
    background: 0 0;
    color: #999;
    cursor: default;
}
.datepicker table tr td.highlighted {
    background: #d9edf7;
    border-radius: 0;
}
.datepicker table tr td.today,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover,
.datepicker table tr td.today:hover {
    background-color: #fde19a;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#fdd49a), to(#fdf59a));
    background-image: linear-gradient(to bottom, #fdd49a, #fdf59a);
    background-repeat: repeat-x;
    border-color: #fdf59a #fdf59a #fbed50;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    color: #000;
}
.datepicker table tr td.today.active,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled.active,
.datepicker table tr td.today.disabled.disabled,
.datepicker table tr td.today.disabled:active,
.datepicker table tr td.today.disabled:hover,
.datepicker table tr td.today.disabled:hover.active,
.datepicker table tr td.today.disabled:hover.disabled,
.datepicker table tr td.today.disabled:hover:active,
.datepicker table tr td.today.disabled:hover:hover,
.datepicker table tr td.today.disabled:hover[disabled],
.datepicker table tr td.today.disabled[disabled],
.datepicker table tr td.today:active,
.datepicker table tr td.today:hover,
.datepicker table tr td.today:hover.active,
.datepicker table tr td.today:hover.disabled,
.datepicker table tr td.today:hover:active,
.datepicker table tr td.today:hover:hover,
.datepicker table tr td.today:hover[disabled],
.datepicker table tr td.today[disabled] {
    background-color: #fdf59a;
}
.datepicker table tr td.today:hover:hover {
    color: #000;
}
.datepicker table tr td.today.active:hover {
    color: #fff;
}
.datepicker table tr td.range,
.datepicker table tr td.range.disabled,
.datepicker table tr td.range.disabled:hover,
.datepicker table tr td.range:hover {
    background: #eee;
    border-radius: 0;
}
.datepicker table tr td.range.today,
.datepicker table tr td.range.today.disabled,
.datepicker table tr td.range.today.disabled:hover,
.datepicker table tr td.range.today:hover {
    background-color: #f3d17a;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#f3c17a), to(#f3e97a));
    background-image: linear-gradient(to bottom, #f3c17a, #f3e97a);
    background-repeat: repeat-x;
    border-color: #f3e97a #f3e97a #edde34;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    border-radius: 0;
}
.datepicker table tr td.range.today.active,
.datepicker table tr td.range.today.disabled,
.datepicker table tr td.range.today.disabled.active,
.datepicker table tr td.range.today.disabled.disabled,
.datepicker table tr td.range.today.disabled:active,
.datepicker table tr td.range.today.disabled:hover,
.datepicker table tr td.range.today.disabled:hover.active,
.datepicker table tr td.range.today.disabled:hover.disabled,
.datepicker table tr td.range.today.disabled:hover:active,
.datepicker table tr td.range.today.disabled:hover:hover,
.datepicker table tr td.range.today.disabled:hover[disabled],
.datepicker table tr td.range.today.disabled[disabled],
.datepicker table tr td.range.today:active,
.datepicker table tr td.range.today:hover,
.datepicker table tr td.range.today:hover.active,
.datepicker table tr td.range.today:hover.disabled,
.datepicker table tr td.range.today:hover:active,
.datepicker table tr td.range.today:hover:hover,
.datepicker table tr td.range.today:hover[disabled],
.datepicker table tr td.range.today[disabled] {
    background-color: #f3e97a;
}
.datepicker table tr td.selected,
.datepicker table tr td.selected.disabled,
.datepicker table tr td.selected.disabled:hover,
.datepicker table tr td.selected:hover {
    background-color: #9e9e9e;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#b3b3b3), to(grey));
    background-image: linear-gradient(to bottom, #b3b3b3, grey);
    background-repeat: repeat-x;
    border-color: grey grey #595959;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.datepicker table tr td.selected.active,
.datepicker table tr td.selected.disabled,
.datepicker table tr td.selected.disabled.active,
.datepicker table tr td.selected.disabled.disabled,
.datepicker table tr td.selected.disabled:active,
.datepicker table tr td.selected.disabled:hover,
.datepicker table tr td.selected.disabled:hover.active,
.datepicker table tr td.selected.disabled:hover.disabled,
.datepicker table tr td.selected.disabled:hover:active,
.datepicker table tr td.selected.disabled:hover:hover,
.datepicker table tr td.selected.disabled:hover[disabled],
.datepicker table tr td.selected.disabled[disabled],
.datepicker table tr td.selected:active,
.datepicker table tr td.selected:hover,
.datepicker table tr td.selected:hover.active,
.datepicker table tr td.selected:hover.disabled,
.datepicker table tr td.selected:hover:active,
.datepicker table tr td.selected:hover:hover,
.datepicker table tr td.selected:hover[disabled],
.datepicker table tr td.selected[disabled] {
    background-color: grey;
}
.datepicker table tr td.active,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active:hover {
    background-color: #006dcc;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#08c), to(#04c));
    background-image: linear-gradient(to bottom, #08c, #04c);
    background-repeat: repeat-x;
    border-color: #04c #04c #002a80;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.datepicker table tr td.active.active,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled.disabled,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active.disabled:hover.active,
.datepicker table tr td.active.disabled:hover.disabled,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.disabled:hover:hover,
.datepicker table tr td.active.disabled:hover[disabled],
.datepicker table tr td.active.disabled[disabled],
.datepicker table tr td.active:active,
.datepicker table tr td.active:hover,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active:hover.disabled,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active:hover:hover,
.datepicker table tr td.active:hover[disabled],
.datepicker table tr td.active[disabled] {
    background-color: #04c;
}
.datepicker table tr td span {
    display: block;
    width: 23%;
    height: 54px;
    line-height: 54px;
    float: left;
    margin: 1%;
    cursor: pointer;
    border-radius: 4px;
}
.datepicker table tr td span.focused,
.datepicker table tr td span:hover {
    background: #eee;
}
.datepicker table tr td span.disabled,
.datepicker table tr td span.disabled:hover {
    background: 0 0;
    color: #999;
    cursor: default;
}
.datepicker table tr td span.active,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active:hover {
    background-color: #006dcc;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#08c), to(#04c));
    background-image: linear-gradient(to bottom, #08c, #04c);
    background-repeat: repeat-x;
    border-color: #04c #04c #002a80;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.datepicker table tr td span.active.active,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled.disabled,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active.disabled:hover.active,
.datepicker table tr td span.active.disabled:hover.disabled,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.disabled:hover:hover,
.datepicker table tr td span.active.disabled:hover[disabled],
.datepicker table tr td span.active.disabled[disabled],
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active:hover.disabled,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active:hover:hover,
.datepicker table tr td span.active:hover[disabled],
.datepicker table tr td span.active[disabled] {
    background-color: #04c;
}
.datepicker table tr td span.new,
.datepicker table tr td span.old {
    color: #999;
}
.datepicker .datepicker-switch {
    width: 145px;
}
.datepicker .datepicker-switch,
.datepicker .next,
.datepicker .prev,
.datepicker tfoot tr th {
    cursor: pointer;
}
.datepicker .datepicker-switch:hover,
.datepicker .next:hover,
.datepicker .prev:hover,
.datepicker tfoot tr th:hover {
    background: #eee;
}
.datepicker .next.disabled,
.datepicker .prev.disabled {
    visibility: hidden;
}
.datepicker .cw {
    font-size: 10px;
    width: 12px;
    padding: 0 2px 0 5px;
    vertical-align: middle;
}
.input-append.date .add-on,
.input-prepend.date .add-on {
    cursor: pointer;
}
.input-append.date .add-on i,
.input-prepend.date .add-on i {
    margin-top: 3px;
}
.input-daterange input {
    text-align: center;
}
.input-daterange input:first-child {
    border-radius: 3px 0 0 3px;
}
.input-daterange input:last-child {
    border-radius: 0 3px 3px 0;
}
.input-daterange .add-on {
    display: inline-block;
    width: auto;
    min-width: 16px;
    height: 18px;
    padding: 4px 5px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    text-shadow: 0 1px 0 #fff;
    vertical-align: middle;
    background-color: #eee;
    border: 1px solid #ccc;
    margin-left: -5px;
    margin-right: -5px;
}
.jq-toast-wrap,
.jq-toast-wrap * {
    margin: 0;
    padding: 0;
}
.jq-toast-wrap {
    display: block;
    position: fixed;
    width: 250px;
    pointer-events: none !important;
    letter-spacing: normal;
    z-index: 9000 !important;
}
.jq-toast-wrap.bottom-left {
    bottom: 20px;
    left: 20px;
}
.jq-toast-wrap.bottom-right {
    bottom: 20px;
    right: 40px;
}
.jq-toast-wrap.top-left {
    top: 20px;
    left: 20px;
}
.jq-toast-wrap.top-right {
    top: 20px;
    right: 40px;
}
.jq-toast-single {
    display: block;
    width: 100%;
    padding: 10px;
    margin: 0 0 5px;
    border-radius: 4px;
    font-size: 12px;
    font-family: arial, sans-serif;
    line-height: 17px;
    position: relative;
    pointer-events: all !important;
    background-color: #444;
    color: #fff;
}
.jq-toast-single .h2,
.jq-toast-single h2 {
    font-family: arial, sans-serif;
    font-size: 14px;
    margin: 0 0 7px;
    background: 0 0;
    color: inherit;
    line-height: inherit;
    letter-spacing: normal;
}
.jq-toast-single a {
    color: #eee;
    text-decoration: none;
    font-weight: 700;
    border-bottom: 1px solid #fff;
    padding-bottom: 3px;
    font-size: 12px;
}
.jq-toast-single ul {
    margin: 0 0 0 15px;
    background: 0 0;
    padding: 0;
}
.jq-toast-single ul li {
    list-style-type: disc !important;
    line-height: 17px;
    background: 0 0;
    margin: 0;
    padding: 0;
    letter-spacing: normal;
}
.close-jq-toast-single {
    position: absolute;
    top: 3px;
    right: 7px;
    font-size: 14px;
    cursor: pointer;
}
.jq-toast-loader {
    display: block;
    position: absolute;
    top: -2px;
    height: 5px;
    width: 0;
    left: 0;
    border-radius: 5px;
    background: red;
}
.jq-toast-loaded {
    width: 100%;
}
.jq-has-icon {
    padding: 10px 10px 10px 50px;
    background-repeat: no-repeat;
    background-position: 10px;
}
.jq-icon-info {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGwSURBVEhLtZa9SgNBEMc9sUxxRcoUKSzSWIhXpFMhhYWFhaBg4yPYiWCXZxBLERsLRS3EQkEfwCKdjWJAwSKCgoKCcudv4O5YLrt7EzgXhiU3/4+b2ckmwVjJSpKkQ6wAi4gwhT+z3wRBcEz0yjSseUTrcRyfsHsXmD0AmbHOC9Ii8VImnuXBPglHpQ5wwSVM7sNnTG7Za4JwDdCjxyAiH3nyA2mtaTJufiDZ5dCaqlItILh1NHatfN5skvjx9Z38m69CgzuXmZgVrPIGE763Jx9qKsRozWYw6xOHdER+nn2KkO+Bb+UV5CBN6WC6QtBgbRVozrahAbmm6HtUsgtPC19tFdxXZYBOfkbmFJ1VaHA1VAHjd0pp70oTZzvR+EVrx2Ygfdsq6eu55BHYR8hlcki+n+kERUFG8BrA0BwjeAv2M8WLQBtcy+SD6fNsmnB3AlBLrgTtVW1c2QN4bVWLATaIS60J2Du5y1TiJgjSBvFVZgTmwCU+dAZFoPxGEEs8nyHC9Bwe2GvEJv2WXZb0vjdyFT4Cxk3e/kIqlOGoVLwwPevpYHT+00T+hWwXDf4AJAOUqWcDhbwAAAAASUVORK5CYII=);
    background-color: #31708f;
    color: #d9edf7;
    border-color: #bce8f1;
}
.jq-icon-warning {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGYSURBVEhL5ZSvTsNQFMbXZGICMYGYmJhAQIJAICYQPAACiSDB8AiICQQJT4CqQEwgJvYASAQCiZiYmJhAIBATCARJy+9rTsldd8sKu1M0+dLb057v6/lbq/2rK0mS/TRNj9cWNAKPYIJII7gIxCcQ51cvqID+GIEX8ASG4B1bK5gIZFeQfoJdEXOfgX4QAQg7kH2A65yQ87lyxb27sggkAzAuFhbbg1K2kgCkB1bVwyIR9m2L7PRPIhDUIXgGtyKw575yz3lTNs6X4JXnjV+LKM/m3MydnTbtOKIjtz6VhCBq4vSm3ncdrD2lk0VgUXSVKjVDJXJzijW1RQdsU7F77He8u68koNZTz8Oz5yGa6J3H3lZ0xYgXBK2QymlWWA+RWnYhskLBv2vmE+hBMCtbA7KX5drWyRT/2JsqZ2IvfB9Y4bWDNMFbJRFmC9E74SoS0CqulwjkC0+5bpcV1CZ8NMej4pjy0U+doDQsGyo1hzVJttIjhQ7GnBtRFN1UarUlH8F3xict+HY07rEzoUGPlWcjRFRr4/gChZgc3ZL2d8oAAAAASUVORK5CYII=);
    background-color: #8a6d3b;
    color: #fcf8e3;
    border-color: #faebcc;
}
.jq-icon-error {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHOSURBVEhLrZa/SgNBEMZzh0WKCClSCKaIYOED+AAKeQQLG8HWztLCImBrYadgIdY+gIKNYkBFSwu7CAoqCgkkoGBI/E28PdbLZmeDLgzZzcx83/zZ2SSXC1j9fr+I1Hq93g2yxH4iwM1vkoBWAdxCmpzTxfkN2RcyZNaHFIkSo10+8kgxkXIURV5HGxTmFuc75B2RfQkpxHG8aAgaAFa0tAHqYFfQ7Iwe2yhODk8+J4C7yAoRTWI3w/4klGRgR4lO7Rpn9+gvMyWp+uxFh8+H+ARlgN1nJuJuQAYvNkEnwGFck18Er4q3egEc/oO+mhLdKgRyhdNFiacC0rlOCbhNVz4H9FnAYgDBvU3QIioZlJFLJtsoHYRDfiZoUyIxqCtRpVlANq0EU4dApjrtgezPFad5S19Wgjkc0hNVnuF4HjVA6C7QrSIbylB+oZe3aHgBsqlNqKYH48jXyJKMuAbiyVJ8KzaB3eRc0pg9VwQ4niFryI68qiOi3AbjwdsfnAtk0bCjTLJKr6mrD9g8iq/S/B81hguOMlQTnVyG40wAcjnmgsCNESDrjme7wfftP4P7SP4N3CJZdvzoNyGq2c/HWOXJGsvVg+RA/k2MC/wN6I2YA2Pt8GkAAAAASUVORK5CYII=);
    background-color: #a94442;
    color: #f2dede;
    border-color: #ebccd1;
}
.jq-icon-success {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADsSURBVEhLY2AYBfQMgf///3P8+/evAIgvA/FsIF+BavYDDWMBGroaSMMBiE8VC7AZDrIFaMFnii3AZTjUgsUUWUDA8OdAH6iQbQEhw4HyGsPEcKBXBIC4ARhex4G4BsjmweU1soIFaGg/WtoFZRIZdEvIMhxkCCjXIVsATV6gFGACs4Rsw0EGgIIH3QJYJgHSARQZDrWAB+jawzgs+Q2UO49D7jnRSRGoEFRILcdmEMWGI0cm0JJ2QpYA1RDvcmzJEWhABhD/pqrL0S0CWuABKgnRki9lLseS7g2AlqwHWQSKH4oKLrILpRGhEQCw2LiRUIa4lwAAAABJRU5ErkJggg==);
    color: #dff0d8;
    background-color: #3c763d;
    border-color: #d6e9c6;
}
.select2-container {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: inline-block;
    margin: 0;
    position: relative;
    vertical-align: middle;
}
.select2-container .select2-selection--single {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    height: 28px;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-select: none;
}
.select2-container .select2-selection--single .select2-selection__rendered {
    display: block;
    padding-left: 8px;
    padding-right: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.select2-container .select2-selection--single .select2-selection__clear {
    background-color: transparent;
    border: none;
    font-size: 1em;
}
.select2-container[dir='rtl'] .select2-selection--single .select2-selection__rendered {
    padding-right: 8px;
    padding-left: 20px;
}
.select2-container .select2-selection--multiple {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    min-height: 32px;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-select: none;
}
.select2-container .select2-selection--multiple .select2-selection__rendered {
    display: inline;
    list-style: none;
    padding: 0;
}
.select2-container .select2-selection--multiple .select2-selection__clear {
    background-color: transparent;
    border: none;
    font-size: 1em;
}
.select2-container .select2-search--inline .select2-search__field {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border: none;
    font-size: 100%;
    margin-top: 5px;
    margin-left: 5px;
    padding: 0;
    max-width: 100%;
    resize: none;
    height: 18px;
    vertical-align: bottom;
    font-family: sans-serif;
    overflow: hidden;
    word-break: keep-all;
}
.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
    -webkit-appearance: none;
}
.select2-dropdown {
    background-color: #fff;
    border: 1px solid #aaa;
    border-radius: 4px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: block;
    position: absolute;
    left: -100000px;
    width: 100%;
    z-index: 1051;
}
.select2-results {
    display: block;
}
.select2-results__options {
    list-style: none;
    margin: 0;
    padding: 0;
}
.select2-results__option {
    padding: 6px;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-select: none;
}
.select2-results__option--selectable {
    cursor: pointer;
}
.select2-container--open .select2-dropdown {
    left: 0;
}
.select2-container--open .select2-dropdown--above {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.select2-container--open .select2-dropdown--below {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.select2-search--dropdown {
    display: block;
    padding: 4px;
}
.select2-search--dropdown .select2-search__field {
    padding: 4px;
    width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
    -webkit-appearance: none;
}
.select2-search--dropdown.select2-search--hide {
    display: none;
}
.select2-close-mask {
    border: 0;
    margin: 0;
    padding: 0;
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    min-height: 100%;
    min-width: 100%;
    height: auto;
    width: auto;
    opacity: 0;
    z-index: 99;
    background-color: #fff;
}
.select2-hidden-accessible {
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
    -webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important;
    height: 1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important;
}
.select2-container--default .select2-selection--single {
    background-color: #fff;
    border: 1px solid #aaa;
    border-radius: 4px;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #444;
    line-height: 28px;
}
.select2-container--default .select2-selection--single .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: 700;
    height: 26px;
    margin-right: 20px;
    padding-right: 0;
}
.select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: #999;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 26px;
    position: absolute;
    top: 1px;
    right: 1px;
    width: 20px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
    border-color: #888 transparent transparent transparent;
    border-style: solid;
    border-width: 5px 4px 0 4px;
    height: 0;
    left: 50%;
    margin-left: -4px;
    margin-top: -2px;
    position: absolute;
    top: 50%;
    width: 0;
}
.select2-container--default[dir='rtl'] .select2-selection--single .select2-selection__clear {
    float: left;
}
.select2-container--default[dir='rtl'] .select2-selection--single .select2-selection__arrow {
    left: 1px;
    right: auto;
}
.select2-container--default.select2-container--disabled .select2-selection--single {
    background-color: #eee;
    cursor: default;
}
.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
    display: none;
}
.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: transparent transparent #888 transparent;
    border-width: 0 4px 5px 4px;
}
.select2-container--default .select2-selection--multiple {
    background-color: #fff;
    border: 1px solid #aaa;
    border-radius: 4px;
    cursor: text;
    padding-bottom: 5px;
    padding-right: 5px;
    position: relative;
}
.select2-container--default .select2-selection--multiple.select2-selection--clearable {
    padding-right: 25px;
}
.select2-container--default .select2-selection--multiple .select2-selection__clear {
    cursor: pointer;
    font-weight: 700;
    height: 20px;
    margin-right: 10px;
    margin-top: 5px;
    position: absolute;
    right: 0;
    padding: 1px;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice {
    background-color: #e4e4e4;
    border: 1px solid #aaa;
    border-radius: 4px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: inline-block;
    margin-left: 5px;
    margin-top: 5px;
    padding: 0;
    padding-left: 20px;
    position: relative;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: bottom;
    white-space: nowrap;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__display {
    cursor: default;
    padding-left: 2px;
    padding-right: 5px;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    background-color: transparent;
    border: none;
    border-right: 1px solid #aaa;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    color: #999;
    cursor: pointer;
    font-size: 1em;
    font-weight: 700;
    padding: 0 4px;
    position: absolute;
    left: 0;
    top: 0;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:focus,
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
    background-color: #f1f1f1;
    color: #333;
    outline: 0;
}
.select2-container--default[dir='rtl'] .select2-selection--multiple .select2-selection__choice {
    margin-left: 5px;
    margin-right: auto;
}
.select2-container--default[dir='rtl'] .select2-selection--multiple .select2-selection__choice__display {
    padding-left: 5px;
    padding-right: 2px;
}
.select2-container--default[dir='rtl'] .select2-selection--multiple .select2-selection__choice__remove {
    border-left: 1px solid #aaa;
    border-right: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
.select2-container--default[dir='rtl'] .select2-selection--multiple .select2-selection__clear {
    float: left;
    margin-left: 10px;
    margin-right: auto;
}
.select2-container--default.select2-container--focus .select2-selection--multiple {
    border: solid #000 1px;
    outline: 0;
}
.select2-container--default.select2-container--disabled .select2-selection--multiple {
    background-color: #eee;
    cursor: default;
}
.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
    display: none;
}
.select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple,
.select2-container--default.select2-container--open.select2-container--above .select2-selection--single {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple,
.select2-container--default.select2-container--open.select2-container--below .select2-selection--single {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.select2-container--default .select2-search--dropdown .select2-search__field {
    border: 1px solid #aaa;
}
.select2-container--default .select2-search--inline .select2-search__field {
    background: 0 0;
    border: none;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-appearance: textfield;
}
.select2-container--default .select2-results > .select2-results__options {
    max-height: 200px;
    overflow-y: auto;
}
.select2-container--default .select2-results__option .select2-results__option {
    padding-left: 1em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
    padding-left: 0;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -1em;
    padding-left: 2em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -2em;
    padding-left: 3em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -3em;
    padding-left: 4em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -4em;
    padding-left: 5em;
}
.select2-container--default
    .select2-results__option
    .select2-results__option
    .select2-results__option
    .select2-results__option
    .select2-results__option
    .select2-results__option
    .select2-results__option {
    margin-left: -5em;
    padding-left: 6em;
}
.select2-container--default .select2-results__option--group {
    padding: 0;
}
.select2-container--default .select2-results__option--disabled {
    color: #999;
}
.select2-container--default .select2-results__option--selected {
    background-color: #ddd;
}
.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
    background-color: #5897fb;
    color: #fff;
}
.select2-container--default .select2-results__group {
    cursor: default;
    display: block;
    padding: 6px;
}
.select2-container--classic .select2-selection--single {
    background-color: #f7f7f7;
    border: 1px solid #aaa;
    border-radius: 4px;
    outline: 0;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(50%, #fff), to(#eee));
    background-image: linear-gradient(to bottom, #fff 50%, #eee 100%);
    background-repeat: repeat-x;
}
.select2-container--classic .select2-selection--single:focus {
    border: 1px solid #5897fb;
}
.select2-container--classic .select2-selection--single .select2-selection__rendered {
    color: #444;
    line-height: 28px;
}
.select2-container--classic .select2-selection--single .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: 700;
    height: 26px;
    margin-right: 20px;
}
.select2-container--classic .select2-selection--single .select2-selection__placeholder {
    color: #999;
}
.select2-container--classic .select2-selection--single .select2-selection__arrow {
    background-color: #ddd;
    border: none;
    border-left: 1px solid #aaa;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    height: 26px;
    position: absolute;
    top: 1px;
    right: 1px;
    width: 20px;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(50%, #eee), to(#ccc));
    background-image: linear-gradient(to bottom, #eee 50%, #ccc 100%);
    background-repeat: repeat-x;
}
.select2-container--classic .select2-selection--single .select2-selection__arrow b {
    border-color: #888 transparent transparent transparent;
    border-style: solid;
    border-width: 5px 4px 0 4px;
    height: 0;
    left: 50%;
    margin-left: -4px;
    margin-top: -2px;
    position: absolute;
    top: 50%;
    width: 0;
}
.select2-container--classic[dir='rtl'] .select2-selection--single .select2-selection__clear {
    float: left;
}
.select2-container--classic[dir='rtl'] .select2-selection--single .select2-selection__arrow {
    border: none;
    border-right: 1px solid #aaa;
    border-radius: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    left: 1px;
    right: auto;
}
.select2-container--classic.select2-container--open .select2-selection--single {
    border: 1px solid #5897fb;
}
.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
    background: 0 0;
    border: none;
}
.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: transparent transparent #888 transparent;
    border-width: 0 4px 5px 4px;
}
.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#fff), color-stop(50%, #eee));
    background-image: linear-gradient(to bottom, #fff 0, #eee 50%);
    background-repeat: repeat-x;
}
.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(50%, #eee), to(#fff));
    background-image: linear-gradient(to bottom, #eee 50%, #fff 100%);
    background-repeat: repeat-x;
}
.select2-container--classic .select2-selection--multiple {
    background-color: #fff;
    border: 1px solid #aaa;
    border-radius: 4px;
    cursor: text;
    outline: 0;
    padding-bottom: 5px;
    padding-right: 5px;
}
.select2-container--classic .select2-selection--multiple:focus {
    border: 1px solid #5897fb;
}
.select2-container--classic .select2-selection--multiple .select2-selection__clear {
    display: none;
}
.select2-container--classic .select2-selection--multiple .select2-selection__choice {
    background-color: #e4e4e4;
    border: 1px solid #aaa;
    border-radius: 4px;
    display: inline-block;
    margin-left: 5px;
    margin-top: 5px;
    padding: 0;
}
.select2-container--classic .select2-selection--multiple .select2-selection__choice__display {
    cursor: default;
    padding-left: 2px;
    padding-right: 5px;
}
.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
    background-color: transparent;
    border: none;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    color: #888;
    cursor: pointer;
    font-size: 1em;
    font-weight: 700;
    padding: 0 4px;
}
.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
    color: #555;
    outline: 0;
}
.select2-container--classic[dir='rtl'] .select2-selection--multiple .select2-selection__choice {
    margin-left: 5px;
    margin-right: auto;
}
.select2-container--classic[dir='rtl'] .select2-selection--multiple .select2-selection__choice__display {
    padding-left: 5px;
    padding-right: 2px;
}
.select2-container--classic[dir='rtl'] .select2-selection--multiple .select2-selection__choice__remove {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
.select2-container--classic.select2-container--open .select2-selection--multiple {
    border: 1px solid #5897fb;
}
.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.select2-container--classic .select2-search--dropdown .select2-search__field {
    border: 1px solid #aaa;
    outline: 0;
}
.select2-container--classic .select2-search--inline .select2-search__field {
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.select2-container--classic .select2-dropdown {
    background-color: #fff;
    border: 1px solid transparent;
}
.select2-container--classic .select2-dropdown--above {
    border-bottom: none;
}
.select2-container--classic .select2-dropdown--below {
    border-top: none;
}
.select2-container--classic .select2-results > .select2-results__options {
    max-height: 200px;
    overflow-y: auto;
}
.select2-container--classic .select2-results__option--group {
    padding: 0;
}
.select2-container--classic .select2-results__option--disabled {
    color: grey;
}
.select2-container--classic .select2-results__option--highlighted.select2-results__option--selectable {
    background-color: #3875d7;
    color: #fff;
}
.select2-container--classic .select2-results__group {
    cursor: default;
    display: block;
    padding: 6px;
}
.select2-container--classic.select2-container--open .select2-dropdown {
    border-color: #5897fb;
}
.jq-toast-wrap,
.jq-toast-wrap * {
    margin: 0;
    padding: 0;
}
.jq-toast-wrap {
    display: block;
    position: fixed;
    width: 250px;
    pointer-events: none !important;
    letter-spacing: normal;
    z-index: 9000 !important;
}
.jq-toast-wrap.bottom-left {
    bottom: 20px;
    left: 20px;
}
.jq-toast-wrap.bottom-right {
    bottom: 20px;
    right: 40px;
}
.jq-toast-wrap.top-left {
    top: 20px;
    left: 20px;
}
.jq-toast-wrap.top-right {
    top: 20px;
    right: 40px;
}
.jq-toast-single {
    display: block;
    width: 100%;
    padding: 10px;
    margin: 0 0 5px;
    border-radius: 4px;
    font-size: 12px;
    font-family: arial, sans-serif;
    line-height: 17px;
    position: relative;
    pointer-events: all !important;
    background-color: #444;
    color: #fff;
}
.jq-toast-single .h2,
.jq-toast-single h2 {
    font-family: arial, sans-serif;
    font-size: 14px;
    margin: 0 0 7px;
    background: 0 0;
    color: inherit;
    line-height: inherit;
    letter-spacing: normal;
}
.jq-toast-single a {
    color: #eee;
    text-decoration: none;
    font-weight: 700;
    border-bottom: 1px solid #fff;
    padding-bottom: 3px;
    font-size: 12px;
}
.jq-toast-single ul {
    margin: 0 0 0 15px;
    background: 0 0;
    padding: 0;
}
.jq-toast-single ul li {
    list-style-type: disc !important;
    line-height: 17px;
    background: 0 0;
    margin: 0;
    padding: 0;
    letter-spacing: normal;
}
.close-jq-toast-single {
    position: absolute;
    top: 3px;
    right: 7px;
    font-size: 14px;
    cursor: pointer;
}
.jq-toast-loader {
    display: block;
    position: absolute;
    top: -2px;
    height: 5px;
    width: 0;
    left: 0;
    border-radius: 5px;
    background: red;
}
.jq-toast-loaded {
    width: 100%;
}
.jq-has-icon {
    padding: 10px 10px 10px 50px;
    background-repeat: no-repeat;
    background-position: 10px;
}
.jq-icon-info {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGwSURBVEhLtZa9SgNBEMc9sUxxRcoUKSzSWIhXpFMhhYWFhaBg4yPYiWCXZxBLERsLRS3EQkEfwCKdjWJAwSKCgoKCcudv4O5YLrt7EzgXhiU3/4+b2ckmwVjJSpKkQ6wAi4gwhT+z3wRBcEz0yjSseUTrcRyfsHsXmD0AmbHOC9Ii8VImnuXBPglHpQ5wwSVM7sNnTG7Za4JwDdCjxyAiH3nyA2mtaTJufiDZ5dCaqlItILh1NHatfN5skvjx9Z38m69CgzuXmZgVrPIGE763Jx9qKsRozWYw6xOHdER+nn2KkO+Bb+UV5CBN6WC6QtBgbRVozrahAbmm6HtUsgtPC19tFdxXZYBOfkbmFJ1VaHA1VAHjd0pp70oTZzvR+EVrx2Ygfdsq6eu55BHYR8hlcki+n+kERUFG8BrA0BwjeAv2M8WLQBtcy+SD6fNsmnB3AlBLrgTtVW1c2QN4bVWLATaIS60J2Du5y1TiJgjSBvFVZgTmwCU+dAZFoPxGEEs8nyHC9Bwe2GvEJv2WXZb0vjdyFT4Cxk3e/kIqlOGoVLwwPevpYHT+00T+hWwXDf4AJAOUqWcDhbwAAAAASUVORK5CYII=);
    background-color: #31708f;
    color: #d9edf7;
    border-color: #bce8f1;
}
.jq-icon-warning {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGYSURBVEhL5ZSvTsNQFMbXZGICMYGYmJhAQIJAICYQPAACiSDB8AiICQQJT4CqQEwgJvYASAQCiZiYmJhAIBATCARJy+9rTsldd8sKu1M0+dLb057v6/lbq/2rK0mS/TRNj9cWNAKPYIJII7gIxCcQ51cvqID+GIEX8ASG4B1bK5gIZFeQfoJdEXOfgX4QAQg7kH2A65yQ87lyxb27sggkAzAuFhbbg1K2kgCkB1bVwyIR9m2L7PRPIhDUIXgGtyKw575yz3lTNs6X4JXnjV+LKM/m3MydnTbtOKIjtz6VhCBq4vSm3ncdrD2lk0VgUXSVKjVDJXJzijW1RQdsU7F77He8u68koNZTz8Oz5yGa6J3H3lZ0xYgXBK2QymlWWA+RWnYhskLBv2vmE+hBMCtbA7KX5drWyRT/2JsqZ2IvfB9Y4bWDNMFbJRFmC9E74SoS0CqulwjkC0+5bpcV1CZ8NMej4pjy0U+doDQsGyo1hzVJttIjhQ7GnBtRFN1UarUlH8F3xict+HY07rEzoUGPlWcjRFRr4/gChZgc3ZL2d8oAAAAASUVORK5CYII=);
    background-color: #8a6d3b;
    color: #fcf8e3;
    border-color: #faebcc;
}
.jq-icon-error {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHOSURBVEhLrZa/SgNBEMZzh0WKCClSCKaIYOED+AAKeQQLG8HWztLCImBrYadgIdY+gIKNYkBFSwu7CAoqCgkkoGBI/E28PdbLZmeDLgzZzcx83/zZ2SSXC1j9fr+I1Hq93g2yxH4iwM1vkoBWAdxCmpzTxfkN2RcyZNaHFIkSo10+8kgxkXIURV5HGxTmFuc75B2RfQkpxHG8aAgaAFa0tAHqYFfQ7Iwe2yhODk8+J4C7yAoRTWI3w/4klGRgR4lO7Rpn9+gvMyWp+uxFh8+H+ARlgN1nJuJuQAYvNkEnwGFck18Er4q3egEc/oO+mhLdKgRyhdNFiacC0rlOCbhNVz4H9FnAYgDBvU3QIioZlJFLJtsoHYRDfiZoUyIxqCtRpVlANq0EU4dApjrtgezPFad5S19Wgjkc0hNVnuF4HjVA6C7QrSIbylB+oZe3aHgBsqlNqKYH48jXyJKMuAbiyVJ8KzaB3eRc0pg9VwQ4niFryI68qiOi3AbjwdsfnAtk0bCjTLJKr6mrD9g8iq/S/B81hguOMlQTnVyG40wAcjnmgsCNESDrjme7wfftP4P7SP4N3CJZdvzoNyGq2c/HWOXJGsvVg+RA/k2MC/wN6I2YA2Pt8GkAAAAASUVORK5CYII=);
    background-color: #a94442;
    color: #f2dede;
    border-color: #ebccd1;
}
.jq-icon-success {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADsSURBVEhLY2AYBfQMgf///3P8+/evAIgvA/FsIF+BavYDDWMBGroaSMMBiE8VC7AZDrIFaMFnii3AZTjUgsUUWUDA8OdAH6iQbQEhw4HyGsPEcKBXBIC4ARhex4G4BsjmweU1soIFaGg/WtoFZRIZdEvIMhxkCCjXIVsATV6gFGACs4Rsw0EGgIIH3QJYJgHSARQZDrWAB+jawzgs+Q2UO49D7jnRSRGoEFRILcdmEMWGI0cm0JJ2QpYA1RDvcmzJEWhABhD/pqrL0S0CWuABKgnRki9lLseS7g2AlqwHWQSKH4oKLrILpRGhEQCw2LiRUIa4lwAAAABJRU5ErkJggg==);
    color: #dff0d8;
    background-color: #3c763d;
    border-color: #d6e9c6;
} /*!
 * Timepicker Component for Twitter Bootstrap
 *
 * Copyright 2013 Joris de Wit
 *
 * Contributors https://github.com/jdewit/bootstrap-timepicker/graphs/contributors
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
.bootstrap-timepicker {
    position: relative;
}
.bootstrap-timepicker.pull-right .bootstrap-timepicker-widget.dropdown-menu {
    left: auto;
    right: 0;
}
.bootstrap-timepicker.pull-right .bootstrap-timepicker-widget.dropdown-menu:before {
    left: auto;
    right: 12px;
}
.bootstrap-timepicker.pull-right .bootstrap-timepicker-widget.dropdown-menu:after {
    left: auto;
    right: 13px;
}
.bootstrap-timepicker .input-group-addon {
    cursor: pointer;
}
.bootstrap-timepicker .input-group-addon i {
    display: inline-block;
    width: 16px;
    height: 16px;
}
.bootstrap-timepicker-widget.dropdown-menu {
    padding: 4px;
}
.bootstrap-timepicker-widget.dropdown-menu.open {
    display: inline-block;
}
.bootstrap-timepicker-widget.dropdown-menu:before {
    border-bottom: 7px solid rgba(0, 0, 0, 0.2);
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    content: '';
    display: inline-block;
    position: absolute;
}
.bootstrap-timepicker-widget.dropdown-menu:after {
    border-bottom: 6px solid #fff;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    content: '';
    display: inline-block;
    position: absolute;
}
.bootstrap-timepicker-widget.timepicker-orient-left:before {
    left: 6px;
}
.bootstrap-timepicker-widget.timepicker-orient-left:after {
    left: 7px;
}
.bootstrap-timepicker-widget.timepicker-orient-right:before {
    right: 6px;
}
.bootstrap-timepicker-widget.timepicker-orient-right:after {
    right: 7px;
}
.bootstrap-timepicker-widget.timepicker-orient-top:before {
    top: -7px;
}
.bootstrap-timepicker-widget.timepicker-orient-top:after {
    top: -6px;
}
.bootstrap-timepicker-widget.timepicker-orient-bottom:before {
    bottom: -7px;
    border-bottom: 0;
    border-top: 7px solid #999;
}
.bootstrap-timepicker-widget.timepicker-orient-bottom:after {
    bottom: -6px;
    border-bottom: 0;
    border-top: 6px solid #fff;
}
.bootstrap-timepicker-widget a.btn,
.bootstrap-timepicker-widget input {
    border-radius: 4px;
}
.bootstrap-timepicker-widget table {
    width: 100%;
    margin: 0;
}
.bootstrap-timepicker-widget table td {
    text-align: center;
    height: 30px;
    margin: 0;
    padding: 2px;
}
.bootstrap-timepicker-widget table td:not(.separator) {
    min-width: 30px;
}
.bootstrap-timepicker-widget table td span {
    width: 100%;
}
.bootstrap-timepicker-widget table td a {
    border: 1px transparent solid;
    width: 100%;
    display: inline-block;
    margin: 0;
    padding: 8px 0;
    outline: 0;
    color: #333;
}
.bootstrap-timepicker-widget table td a:hover {
    text-decoration: none;
    background-color: #eee;
    border-radius: 4px;
    border-color: #ddd;
}
.bootstrap-timepicker-widget table td a i {
    margin-top: 2px;
    font-size: 18px;
}
.bootstrap-timepicker-widget table td input {
    width: 25px;
    margin: 0;
    text-align: center;
}
.bootstrap-timepicker-widget .modal-content {
    padding: 4px;
}
@media (min-width: 767px) {
    .bootstrap-timepicker-widget.modal {
        width: 200px;
        margin-left: -100px;
    }
}
@media (max-width: 767px) {
    .bootstrap-timepicker {
        width: 100%;
    }
    .bootstrap-timepicker .dropdown-menu {
        width: 100%;
    }
}
.bootstrap-touchspin .input-group-btn-vertical {
    position: absolute;
    right: 0;
    height: 100%;
    z-index: 11;
}
.bootstrap-touchspin .input-group-btn-vertical > .btn {
    position: absolute;
    right: 0;
    height: 50%;
    padding: 0;
    width: 2em;
    text-align: center;
    line-height: 1;
}
.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-up {
    border-radius: 0 4px 0 0;
    top: 0;
}
.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-down {
    border-radius: 0 0 4px 0;
    bottom: 0;
}
.apex-charts {
    min-height: 10px !important;
}
.apex-charts text {
    font-family: Nunito, sans-serif !important;
    fill: #adb5bd;
}
.apex-charts .apexcharts-canvas {
    margin: 0 auto;
}
.apexcharts-tooltip-text,
.apexcharts-tooltip-title {
    font-family: Nunito, sans-serif !important;
}
.apexcharts-legend-series {
    font-weight: 600;
}
.apexcharts-gridline {
    pointer-events: none;
    stroke: #f9f9fd;
}
.apexcharts-legend-text {
    color: #98a6ad !important;
    font-family: Nunito, sans-serif !important;
}
.apexcharts-xaxis text,
.apexcharts-yaxis text {
    font-family: Nunito, sans-serif !important;
    fill: #adb5bd;
}
.apexcharts-point-annotations text,
.apexcharts-xaxis-annotations text,
.apexcharts-yaxis-annotations text {
    fill: #fff;
}
.apexcharts-radar-series polygon {
    fill: transparent;
    stroke: #dee2e6;
}
.apexcharts-radar-series line {
    stroke: #dee2e6;
}
.apexcharts-datalabel,
.apexcharts-datalabel-label,
.apexcharts-datalabel-value,
.apexcharts-pie-label {
    fill: #98a6ad !important;
}
.apexcharts-datalabels-group text {
    fill: #adb5bd !important;
}
.scatter-images-chart .apexcharts-legend {
    overflow: hidden !important;
    min-height: 17px;
}
.scatter-images-chart .apexcharts-legend-marker {
    background: 0 0 !important;
    margin-right: 7px !important;
}
.scatter-images-chart .apexcharts-legend-series {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
}
.apexcharts-pie-series path {
    stroke: transparent !important;
}
.apexcharts-track path {
    stroke: #edeff1;
}
.britechart,
.tick text {
    font-family: Nunito, sans-serif;
    font-size: 0.75rem;
}
.extended-x-line,
.extended-y-line,
.horizontal-grid-line,
.vertical-grid-line {
    stroke: #dee2e6;
}
.bar-chart .percentage-label,
.donut-text,
.legend-entry-name,
.legend-entry-value,
.tick text {
    fill: #98a6ad;
}
.calendar {
    float: left;
    margin-bottom: 0;
}
.fc-view {
    margin-top: 30px;
}
.none-border .modal-footer {
    border-top: none;
}
.fc-toolbar {
    margin: 6px 0 5px 0 !important;
}
.fc-toolbar .h2,
.fc-toolbar h2 {
    font-size: 1.25rem !important;
    line-height: 1.875rem;
    text-transform: uppercase;
}
.fc-day-grid-event .fc-time {
    font-weight: 600;
}
th.fc-day-header {
    padding: 0.5rem 0;
}
.fc-day {
    background: 0 0;
}
.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active,
.fc-toolbar .ui-state-hover,
.fc-toolbar button:focus,
.fc-toolbar button:hover {
    z-index: 0;
}
.fc th.fc-widget-header {
    background: #dee2e6;
    font-size: 13px;
    line-height: 20px;
    padding: 10px 0;
    text-transform: uppercase;
    font-weight: 600;
}
.fc-unthemed .fc-divider,
.fc-unthemed .fc-popover,
.fc-unthemed .fc-row,
.fc-unthemed tbody,
.fc-unthemed td,
.fc-unthemed th,
.fc-unthemed thead {
    border-color: #dee2e6;
}
.fc-unthemed .fc-divider,
.fc-unthemed td.fc-today {
    background: #dee2e6;
}
.fc-button {
    background: #dee2e6;
    border: none;
    color: #6c757d;
    text-transform: capitalize;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 3px;
    margin: 0 3px;
    padding: 6px 12px;
    height: auto;
}
.fc-text-arrow {
    font-family: inherit;
    font-size: 1rem;
}
.fc-cell-overlay,
.fc-state-highlight,
.fc-state-hover {
    background: #dee2e6;
}
.fc-state-active,
.fc-state-disabled,
.fc-state-down {
    background-color: #536de6;
    color: #fff;
    text-shadow: none;
}
.fc-unthemed .fc-today {
    background: #fff;
}
.fc-event {
    border-radius: 2px;
    border: none;
    cursor: move;
    font-size: 0.8125rem;
    margin: 5px 7px;
    padding: 5px 5px;
    text-align: center;
    color: #fff;
}
.external-event {
    cursor: move;
    margin: 10px 0;
    padding: 8px 10px;
    color: #fff;
    border-radius: 4px;
}
.fc-basic-view td.fc-week-number span {
    padding-right: 8px;
}
.fc-basic-view td.fc-day-number {
    padding-right: 8px;
}
.fc-basic-view .fc-content {
    color: #fff;
}
.fc-time-grid-event .fc-content {
    color: #fff;
}
.fc-daygrid-day-number {
    float: right;
    height: 20px;
    width: 20px;
    text-align: center;
    line-height: 20px;
    background-color: #f1f3fa;
    border-radius: 50%;
    margin: 5px;
    font-size: 11px;
    padding: 0 !important;
}
.fc-daygrid-event-dot {
    border-color: #fff;
}
.fc-event-time,
.fc-event-title {
    color: #fff;
}
.fc .fc-list-sticky .fc-list-day > *,
.table-active,
.table-active > td,
.table-active > th {
    background-color: transparent;
}
.fc .fc-list-event:hover td {
    background-color: inherit;
}
@media (max-width: 767.98px) {
    .fc-toolbar {
        display: block !important;
    }
    .fc-toolbar .fc-toolbar-chunk {
        margin: 6px 0;
    }
    .fc-toolbar .fc-center,
    .fc-toolbar .fc-left,
    .fc-toolbar .fc-right {
        float: none;
        display: block;
        clear: both;
        margin: 10px 0;
    }
    .fc .fc-toolbar > * > * {
        float: none;
    }
    .fc-today-button {
        display: none;
    }
}
.fc-toolbar .btn {
    padding: 0.28rem 0.8rem;
    font-size: 0.875rem;
    border-radius: 0.15rem;
}
.fc-list-item-time,
.fc-list-item-title {
    color: #fff;
}
#calendar .table-bordered td,
#calendar .table-bordered th {
    border: 1px solid #eef2f7;
}
[dir='rtl'] .fc-toolbar-chunk .btn-group .btn:first-child {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-radius: 0.15rem;
}
[dir='rtl'] .fc-toolbar-chunk .btn-group .btn:last-child {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-radius: 0.15rem;
}
.chartjs-chart {
    margin: auto;
    position: relative;
    width: 100%;
}
.dataTables_wrapper.container-fluid,
.dataTables_wrapper.container-lg,
.dataTables_wrapper.container-md,
.dataTables_wrapper.container-sm,
.dataTables_wrapper.container-xl,
.dataTables_wrapper.container-xxl {
    padding: 0;
}
table.dataTable {
    border-collapse: collapse !important;
    margin-bottom: 15px !important;
}
table.dataTable tr.odd {
    background-color: #f1f3fa;
}
table.dataTable tr.odd td {
    background-color: #f1f3fa;
}
table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_desc_disabled:before {
    right: 0.5rem;
    left: auto;
    content: '\f0360';
    font-family: 'Material Design Icons';
    font-size: 1rem;
    top: 12px;
}
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_desc_disabled:after {
    right: 0.5em;
    left: auto;
    content: '\f035d';
    font-family: 'Material Design Icons';
    top: 18px;
    font-size: 1rem;
}
table.dataTable thead > tr > th.sorting,
table.dataTable thead > tr > th.sorting_asc,
table.dataTable thead > tr > th.sorting_desc {
    padding-right: 30px;
    padding-left: 0.95rem;
}
table.dataTable tbody > tr.selected,
table.dataTable tbody > tr > .selected {
    background-color: #536de6;
}
table.dataTable tbody > tr.selected td,
table.dataTable tbody > tr > .selected td {
    border-color: #536de6;
}
table.dataTable tbody td:focus {
    outline: 0 !important;
}
table.dataTable tbody td.focus,
table.dataTable tbody th.focus {
    outline: 2px solid #536de6 !important;
    outline-offset: -1px;
    background-color: rgba(83, 109, 230, 0.15);
}
.dataTables_info {
    font-weight: 600;
}
table.dataTable.dtr-inline.collapsed > tbody > tr[role='row'] > td:first-child:before,
table.dataTable.dtr-inline.collapsed > tbody > tr[role='row'] > th:first-child:before {
    -webkit-box-shadow: 0 0 45px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 0 45px 0 rgba(0, 0, 0, 0.12);
    background-color: #10c469;
    top: auto;
    bottom: auto;
}
table.dataTable.dtr-inline.collapsed > tbody > tr.parent > td:first-child:before,
table.dataTable.dtr-inline.collapsed > tbody > tr.parent > th:first-child:before {
    background-color: #ff5b5b;
}
div.dt-button-info {
    background-color: #536de6;
    border: none;
    color: #fff;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 3px;
    text-align: center;
    z-index: 21;
}
div.dt-button-info .h2,
div.dt-button-info h2 {
    border-bottom: none;
    background-color: rgba(255, 255, 255, 0.2);
    color: #fff;
}
@media (max-width: 767.98px) {
    li.paginate_button.next,
    li.paginate_button.previous {
        display: inline-block;
        font-size: 1.5rem;
    }
    li.paginate_button {
        display: none;
    }
    .dataTables_paginate ul {
        text-align: center;
        display: block;
        margin: 1.5rem 0 0 !important;
    }
    div.dt-buttons {
        display: inline-table;
        margin-bottom: 1.5rem;
    }
}
.activate-select .sorting_1 {
    background-color: #f1f3fa;
}
div.dataTables_wrapper div.dataTables_filter {
    text-align: right;
}
@media (max-width: 576px) {
    div.dataTables_wrapper div.dataTables_filter {
        text-align: center;
    }
}
div.dataTables_wrapper div.dataTables_filter input {
    margin-left: 0.5em;
    margin-right: 0;
}
div.dataTables_wrapper div.dataTables_length {
    text-align: left;
}
@media (max-width: 576px) {
    div.dataTables_wrapper div.dataTables_length {
        text-align: center;
    }
}
div.table-responsive > div.dataTables_wrapper > div.row > div[class^='col-']:last-child {
    padding-right: 0;
}
div.table-responsive > div.dataTables_wrapper > div.row > div[class^='col-']:first-child {
    padding-left: 0;
}
div.dataTables_scrollBody {
    border-left: none !important;
}
div.dataTables_scrollBody > table {
    margin-bottom: 15px !important;
}
div.dataTables_scrollBody > table > :not(:first-child) {
    border-top: none !important;
}
.daterangepicker {
    font-family: Nunito, sans-serif;
    border: 1px solid #e7ebf0;
    -webkit-box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
    box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
    background-color: #fff;
}
.daterangepicker .calendar-table {
    border: 1px solid #fff;
    background-color: #fff;
}
.daterangepicker .calendar-table td,
.daterangepicker .calendar-table th {
    color: #98a6ad;
}
.daterangepicker .calendar-table .next span,
.daterangepicker .calendar-table .prev span {
    border-color: #adb5bd;
}
.daterangepicker .ranges li:hover {
    background-color: #f4f6fb;
}
.daterangepicker .ranges li.active {
    background-color: #536de6;
}
.daterangepicker td.in-range {
    background-color: #edeff1;
    color: #6c757d;
}
.daterangepicker td.off,
.daterangepicker td.off.end-date,
.daterangepicker td.off.in-range,
.daterangepicker td.off.start-date {
    background-color: #fff;
    color: #6c757d;
    opacity: 0.5;
}
.daterangepicker td.active,
.daterangepicker td.active:hover {
    background-color: #536de6;
    color: #fff;
}
.daterangepicker td.available:hover,
.daterangepicker th.available:hover {
    background-color: #536de6;
    color: #fff;
}
.daterangepicker:after {
    border-bottom: 6px solid #fff;
}
.daterangepicker:before {
    border-bottom: 7px solid #e7ebf0;
}
.daterangepicker .drp-buttons {
    border-top: 1px solid #e7ebf0;
}
.daterangepicker select.ampmselect,
.daterangepicker select.hourselect,
.daterangepicker select.minuteselect,
.daterangepicker select.secondselect {
    background: #fff;
    border: 1px solid #fff;
    color: #6c757d;
}
.daterangepicker.show-ranges .drp-calendar.left {
    border-left: 1px solid #e7ebf0;
}
.datepicker {
    padding: 10px !important;
}
.datepicker td,
.datepicker th {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    font-size: 13px;
    line-height: 28px;
}
.datepicker table tr td .active.disabled:hover[disabled],
.datepicker table tr td .active.disabled[disabled],
.datepicker table tr td .active:active,
.datepicker table tr td .active:hover,
.datepicker table tr td .active:hover.active,
.datepicker table tr td .active:hover.disabled,
.datepicker table tr td .active:hover:active,
.datepicker table tr td .active:hover:hover,
.datepicker table tr td .active:hover[disabled],
.datepicker table tr td .active[disabled],
.datepicker table tr td span.active.active,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled.disabled,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active.disabled:hover.active,
.datepicker table tr td span.active.disabled:hover.disabled,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.disabled:hover:hover,
.datepicker table tr td span.active.disabled:hover[disabled],
.datepicker table tr td span.active.disabled[disabled],
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active:hover.disabled,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active:hover:hover,
.datepicker table tr td span.active:hover[disabled],
.datepicker table tr td span.active[disabled],
.datepicker table tr td.active.active,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled.disabled,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active.disabled:hover.active,
.datepicker table tr td.active.disabled:hover.disabled,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.disabled:hover:hover,
.datepicker table tr td.today,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover,
.datepicker table tr td.today:hover {
    background-color: #536de6 !important;
    background-image: none !important;
    color: #fff;
}
.datepicker table tr td span.focused,
.datepicker table tr td span:hover,
.datepicker table tr td.day.focused,
.datepicker table tr td.day:hover {
    background: #eef2f7;
}
.datepicker table tr td span.new,
.datepicker table tr td span.old,
.datepicker table tr td.new,
.datepicker table tr td.old {
    color: #6c757d;
    opacity: 0.4;
}
.datepicker .datepicker-switch:hover,
.datepicker .next:hover,
.datepicker .prev:hover,
.datepicker tfoot tr th:hover {
    background: #eef2f7;
}
.datepicker .datepicker-switch:hover {
    background: 0 0;
}
.datepicker-dropdown:after {
    border-bottom: 6px solid #fff;
}
.datepicker-dropdown:before {
    border-bottom-color: #e7ebf0;
}
.datepicker-dropdown.datepicker-orient-top:before {
    border-top: 7px solid #e7ebf0;
}
.datepicker-dropdown.datepicker-orient-top:after {
    border-top: 6px solid #fff;
}
.daterangepicker {
    z-index: 1000;
}
[dir='rtl'] .daterangepicker.opensright:after {
    right: 10px;
    left: auto;
}
[dir='rtl'] .daterangepicker.opensright:before {
    right: 9px;
    left: auto;
}
.bg-dragula {
    background-color: #f7f9fb;
}
.gu-mirror {
    position: fixed !important;
    margin: 0 !important;
    z-index: 9999 !important;
    opacity: 0.8;
}
.gu-hide {
    display: none !important;
}
.gu-unselectable {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
}
.gu-transit {
    opacity: 0.2;
}
.dragula-handle {
    position: relative;
    width: 36px;
    height: 36px;
    font-size: 24px;
    text-align: center;
    cursor: move;
}
.dragula-handle:before {
    content: '\f01db';
    font-family: 'Material Design Icons';
    position: absolute;
}
.dropzone {
    border: 2px dashed #dee2e6;
    background: #fff;
    border-radius: 6px;
    cursor: pointer;
    min-height: 150px;
    padding: 20px;
}
.dropzone .dz-message {
    text-align: center;
    margin: 2rem 0;
}
.dropzone.dz-started .dz-message {
    display: none;
}
.form-wizard-header {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    background-color: #eef2f7;
}
.gmaps,
.gmaps-panaroma {
    height: 300px;
    background: #f1f3fa;
    border-radius: 3px;
}
.jvectormap-label {
    border: none;
    background: #343a40;
    color: #f1f3fa;
    font-family: Nunito, sans-serif;
    font-size: 0.9rem;
    padding: 5px 8px;
}
.ql-editor {
    text-align: left;
}
.ql-editor ol,
.ql-editor ul {
    padding-left: 1.5em;
    padding-right: 0;
}
.ql-editor ol li:not(.ql-direction-rtl),
.ql-editor ul li:not(.ql-direction-rtl) {
    padding-left: 1.5em;
    padding-right: 0;
}
.ql-editor ol li:before,
.ql-editor ul li:before {
    margin-left: -1.5em !important;
    margin-right: 0.3em !important;
}
.ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
    right: 0;
    left: auto;
}
.ql-container {
    font-family: Nunito, sans-serif;
}
.ql-container.ql-snow {
    border-color: #dee2e6;
}
.ql-bubble {
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
}
.ql-toolbar {
    font-family: Nunito, sans-serif !important;
}
.ql-toolbar span {
    outline: 0 !important;
    color: #6c757d;
}
.ql-toolbar span:hover {
    color: #536de6 !important;
}
.ql-toolbar.ql-snow {
    border-color: #dee2e6;
}
.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
    border-color: transparent;
}
.ql-snow .ql-script,
.ql-snow .ql-strike svg,
.ql-snow .ql-stroke {
    stroke: #6c757d;
}
.ql-snow .ql-fill {
    fill: #6c757d;
}
.ql-snow .ql-picker-options {
    background-color: #fff;
    border-color: #e7ebf0 !important;
}
.select2-container {
    width: 100% !important;
}
.select2-container .select2-selection--single {
    border: 1px solid #dee2e6;
    height: calc(1.5em + 0.9rem + 2px);
    background-color: #fff;
    outline: 0;
}
.select2-container .select2-selection--single .select2-selection__rendered {
    line-height: 36px;
    padding-left: 12px;
    color: #6c757d;
}
.select2-container .select2-selection--single .select2-selection__arrow {
    height: 34px;
    width: 34px;
    right: 3px;
}
.select2-container .select2-selection--single .select2-selection__arrow b {
    border-color: #98a6ad transparent transparent transparent;
    border-width: 6px 6px 0 6px;
}
.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: transparent transparent #98a6ad transparent !important;
    border-width: 0 6px 6px 6px !important;
}
.select2-results__option {
    padding: 6px 12px;
}
.select2-dropdown {
    border: 1px solid #e7ebf0;
    -webkit-box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
    box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
    background-color: #fff;
}
.select2-container--default .select2-search--dropdown {
    padding: 10px;
    background-color: #fff;
}
.select2-container--default .select2-search--dropdown .select2-search__field {
    outline: 0;
    border: 1px solid #dee2e6;
    background-color: #fff;
    color: #6c757d;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: #536de6;
}
.select2-container--default .select2-results__option[aria-selected='true'] {
    background-color: #fff;
    color: #323a46;
}
.select2-container--default .select2-results__option[aria-selected='true']:hover {
    background-color: #536de6;
    color: #fff;
}
.select2-container .select2-selection--multiple {
    min-height: calc(1.5em + 0.9rem + 2px);
    border: 1px solid #dee2e6 !important;
    background-color: #fff;
}
.select2-container .select2-selection--multiple .select2-selection__rendered {
    padding: 1px 4px;
}
.select2-container .select2-selection--multiple .select2-search__field {
    border: 0;
    color: #6c757d;
}
.select2-container .select2-selection--multiple .select2-selection__choice {
    background-color: #536de6;
    border: none;
    color: #fff;
    border-radius: 3px;
    padding: 0 7px 0 0;
    margin-top: 6px;
}
.select2-container .select2-selection--multiple .select2-selection__choice__remove {
    color: #fff;
    margin-right: 7px;
    border-color: #647ce9;
    padding: 0 8px;
}
.select2-container .select2-selection--multiple .select2-selection__choice__remove:hover {
    color: #fff;
    background-color: #536de6;
}
.select2-container .select2-search--inline .select2-search__field {
    margin-top: 7px;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__display {
    padding-left: 36px;
    padding-right: 5px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
    right: 1px;
    left: auto;
}
[dir='rtl'] .select2-container--open .select2-dropdown {
    left: auto;
    right: 0;
}
[data-simplebar] {
    position: relative;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}
.simplebar-wrapper {
    overflow: hidden;
    width: inherit;
    height: inherit;
    max-width: inherit;
    max-height: inherit;
}
.simplebar-mask {
    direction: inherit;
    position: absolute;
    overflow: hidden;
    padding: 0;
    margin: 0;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: auto !important;
    height: auto !important;
    z-index: 0;
}
.simplebar-offset {
    direction: inherit !important;
    -webkit-box-sizing: inherit !important;
    box-sizing: inherit !important;
    resize: none !important;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 0;
    margin: 0;
    -webkit-overflow-scrolling: touch;
}
.simplebar-content-wrapper {
    direction: inherit;
    -webkit-box-sizing: border-box !important;
    box-sizing: border-box !important;
    position: relative;
    display: block;
    height: 100%;
    width: auto;
    overflow: auto;
    max-width: 100%;
    max-height: 100%;
    scrollbar-width: none;
}
.simplebar-content-wrapper::-webkit-scrollbar,
.simplebar-hide-scrollbar::-webkit-scrollbar {
    display: none;
}
.simplebar-content:after,
.simplebar-content:before {
    content: ' ';
    display: table;
}
.simplebar-placeholder {
    max-height: 100%;
    max-width: 100%;
    width: 100%;
    pointer-events: none;
}
.simplebar-height-auto-observer-wrapper {
    -webkit-box-sizing: inherit !important;
    box-sizing: inherit !important;
    height: 100%;
    width: 100%;
    max-width: 1px;
    position: relative;
    float: left;
    max-height: 1px;
    overflow: hidden;
    z-index: -1;
    padding: 0;
    margin: 0;
    pointer-events: none;
    -webkit-box-flex: inherit;
    -ms-flex-positive: inherit;
    flex-grow: inherit;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
}
.simplebar-height-auto-observer {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
    display: block;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 1000%;
    width: 1000%;
    min-height: 1px;
    min-width: 1px;
    overflow: hidden;
    pointer-events: none;
    z-index: -1;
}
.simplebar-track {
    z-index: 1;
    position: absolute;
    right: 0;
    bottom: 0;
    pointer-events: none;
    overflow: hidden;
}
[data-simplebar].simplebar-dragging .simplebar-content {
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
[data-simplebar].simplebar-dragging .simplebar-track {
    pointer-events: all;
}
.simplebar-scrollbar {
    position: absolute;
    right: 2px;
    width: 5px;
    min-height: 10px;
}
.simplebar-scrollbar:before {
    position: absolute;
    content: '';
    background: #a2adb7;
    border-radius: 7px;
    left: 0;
    right: 0;
    opacity: 0;
    -webkit-transition: opacity 0.2s linear;
    transition: opacity 0.2s linear;
}
.simplebar-scrollbar.simplebar-visible:before {
    opacity: 0.5;
    -webkit-transition: opacity 0s linear;
    transition: opacity 0s linear;
}
.simplebar-track.simplebar-vertical {
    top: 0;
    width: 11px;
}
.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
    top: 2px;
    bottom: 2px;
}
.simplebar-track.simplebar-horizontal {
    left: 0;
    height: 11px;
}
.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
    right: auto;
    left: 0;
    top: 2px;
    height: 7px;
    min-height: 0;
    min-width: 10px;
    width: auto;
}
.simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
    height: 100%;
    left: 2px;
    right: 2px;
}
[data-simplebar-direction='rtl'] .simplebar-track.simplebar-vertical {
    right: auto;
    left: 0;
}
.hs-dummy-scrollbar-size {
    direction: rtl;
    position: fixed;
    opacity: 0;
    visibility: hidden;
    height: 500px;
    width: 500px;
    overflow-y: hidden;
    overflow-x: scroll;
}
.simplebar-hide-scrollbar {
    position: fixed;
    left: 0;
    visibility: hidden;
    overflow-y: scroll;
    scrollbar-width: none;
}
.custom-scroll {
    height: 100%;
}
[data-simplebar-lg] .simplebar-scrollbar {
    right: 1px;
    width: 10px;
}
[data-simplebar-primary] .simplebar-scrollbar:before {
    background: #536de6;
}
.jq-toast-single {
    text-align: left !important;
    padding: 15px;
    font-family: Nunito, sans-serif;
    background-color: #536de6;
    font-size: 13px;
    line-height: 22px;
}
.jq-toast-single .h2,
.jq-toast-single h2 {
    font-family: Nunito, sans-serif;
}
.jq-toast-single a {
    font-size: 0.9rem;
}
.jq-toast-single a:hover {
    color: #fff;
}
.jq-has-icon {
    padding: 10px;
}
.close-jq-toast-single {
    position: absolute;
    top: -12px;
    right: -12px;
    font-size: 20px;
    cursor: pointer;
    height: 32px;
    width: 32px;
    background: #343a40;
    color: #f1f3fa;
    border-radius: 50%;
    text-align: center;
    line-height: 32px;
}
.jq-toast-loader {
    height: 3px;
    top: 0;
    border-radius: 0;
}
.jq-icon-primary {
    background-color: #536de6;
    color: #fff;
    border-color: #536de6;
}
.jq-icon-secondary {
    background-color: #6c757d;
    color: #fff;
    border-color: #6c757d;
}
.jq-icon-success {
    background-color: #10c469;
    color: #fff;
    border-color: #10c469;
}
.jq-icon-info {
    background-color: #35b8e0;
    color: #fff;
    border-color: #35b8e0;
}
.jq-icon-warning {
    background-color: #f9c851;
    color: #fff;
    border-color: #f9c851;
}
.jq-icon-danger {
    background-color: #ff5b5b;
    color: #fff;
    border-color: #ff5b5b;
}
.jq-icon-light {
    background-color: #eef2f7;
    color: #fff;
    border-color: #eef2f7;
}
.jq-icon-dark {
    background-color: #323a46;
    color: #fff;
    border-color: #323a46;
}
.jq-icon-error {
    background-color: #ff5b5b;
    color: #fff;
    border-color: #ff5b5b;
}
.jq-icon-error,
.jq-icon-info,
.jq-icon-success,
.jq-icon-warning {
    background-image: none;
}
.bootstrap-touchspin .btn .input-group-text {
    padding: 0;
    border: none;
    background-color: transparent;
    color: inherit;
}
.bootstrap-timepicker-widget table td input {
    height: 32px;
    width: 32px;
    color: #fff;
    background-color: #536de6;
    border-radius: 50%;
    border: 0;
    outline: 0 !important;
}
.bootstrap-timepicker-widget table td a {
    color: #6c757d;
}
.bootstrap-timepicker-widget table td a:hover {
    background-color: transparent;
    border: 1px solid transparent;
    color: #536de6;
}
.bootstrap-timepicker-widget.dropdown-menu:before {
    border-bottom: 7px solid #e7ebf0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
}
.bootstrap-timepicker-widget.dropdown-menu:after {
    border-bottom: 6px solid #fff;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
}
.bootstrap-timepicker-widget.timepicker-orient-bottom:after {
    bottom: -6px;
    border-bottom: 0;
    border-top: 6px solid #fff;
}
.bootstrap-timepicker-widget.timepicker-orient-bottom:before {
    bottom: -7px;
    border-bottom: 0;
    border-top: 7px solid #e7ebf0;
}
.CodeMirror-fullscreen,
.editor-toolbar.fullscreen {
    z-index: 1000;
}
.editor-preview,
.editor-preview-side {
    background: #dee2e6;
}
.editor-preview-active {
    background: #e5e8eb;
}
.editor-toolbar {
    border: 1px solid #dee2e6;
    border-bottom: 0;
}
.editor-toolbar a {
    color: #6c757d !important;
}
.editor-toolbar a.active,
.editor-toolbar a:hover {
    background-color: transparent;
    color: #536de6 !important;
    border-color: transparent;
}
.editor-toolbar i.separator {
    display: none;
}
.editor-toolbar.disabled-for-preview a:not(.no-disable) {
    background: 0 0;
}
.CodeMirror {
    border: 1px solid #dee2e6;
    background: #fff;
    color: #6c757d;
    min-height: 100px;
}
.CodeMirror-cursor {
    border-left: 1px solid #343a40;
}
.editor-statusbar {
    color: #343a40;
}
.twitter-typeahead {
    display: inherit !important;
}
.tt-hint,
.tt-query {
    outline: 0;
}
.tt-query {
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.tt-hint {
    color: #6c757d;
    background: #fff !important;
}
.tt-menu {
    width: 100%;
    padding: 8px 0;
    max-height: 200px;
    overflow-y: auto;
    background-color: #fff;
    -webkit-box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
    box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
    border: 1px solid #e7ebf0;
}
.tt-suggestion {
    padding: 3px 20px;
    line-height: 24px;
}
.tt-suggestion.tt-cursor,
.tt-suggestion:hover {
    cursor: pointer;
    color: #fff;
    background-color: #536de6;
}
.tt-suggestion p {
    margin: 0;
}
.tt-highlight {
    font-family: Nunito, sans-serif;
}
.typeahead-empty-message {
    padding: 5px 10px;
    color: #ff5b5b;
}
.league-name {
    padding: 3px 20px;
}
.jqstooltip {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    width: auto !important;
    height: auto !important;
    background-color: #fff !important;
    -webkit-box-shadow: 0 0 45px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 0 45px 0 rgba(0, 0, 0, 0.12);
    padding: 5px 10px !important;
    border-radius: 3px;
    border-color: #fff !important;
}
.jqsfield {
    color: #000 !important;
    font-size: 12px !important;
    line-height: 18px !important;
    font-family: Nunito, sans-serif !important;
    font-weight: 700 !important;
}
.timepicker-orient-bottom {
    top: auto !important;
    bottom: calc(1.5em + 0.9rem + 2px) !important;
}
.bootstrap-timepicker-widget {
    left: 0 !important;
    right: auto !important;
}
.bootstrap-timepicker-widget.timepicker-orient-left:before {
    left: 6px;
    right: auto;
}
.bootstrap-timepicker-widget.timepicker-orient-left::after {
    left: 7px;
    right: auto;
}
.rateit {
    display: -moz-inline-box;
    display: inline-block;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
}
.rateit .rateit-range * {
    display: block;
}
.rateit .rateit-hover,
.rateit .rateit-selected {
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
}
.rateit .rateit-hover-rtl,
.rateit .rateit-selected-rtl {
    left: auto;
    right: 0;
}
.rateit .rateit-hover {
    color: #f9c851;
}
.rateit .rateit-hover-rtl {
    background-position: right -32px;
}
.rateit .rateit-selected {
    color: #ff5b5b;
}
.rateit .rateit-selected-rtl {
    background-position: right -16px;
}
.rateit .rateit-preset {
    color: #fd7e14;
}
.rateit button.rateit-reset {
    width: 16px;
    height: 16px;
    float: left;
    outline: 0;
    border: none;
    padding: 0;
}
.rateit .rateit-reset span {
    display: none;
}
.rateit .rateit-range {
    position: relative;
    display: -moz-inline-box;
    display: inline-block;
    height: 16px;
    outline: 0;
}
.rateit.rateit-font .rateit-reset {
    width: 0.6em;
    height: 0.6em;
    margin-right: 5px;
    margin-top: 5px;
    background: #dee2e6;
    border-radius: 50%;
    position: relative;
}
.rateit.rateit-font .rateit-reset span {
    display: block;
    height: calc(50% - 0.045em);
    top: 2px;
    position: absolute;
    border-bottom: 2px solid #6c757d;
    width: 50%;
    margin-left: 25%;
    margin-right: 25%;
}
.rateit.rateit-font .rateit-reset:hover,
.rateit.rateit-font button.rateit-reset:focus {
    background: #ff5b5b;
}
.rateit.rateit-font .rateit-reset:hover span,
.rateit.rateit-font button.rateit-reset:focus span {
    border-color: #fff;
}
.rateit-mdi {
    font-family: 'Material Design Icons';
}
.rateit-font {
    font-size: 24px;
    line-height: 1em;
}
.rateit-font .rateit-range {
    background: 0 0;
    height: auto;
}
.rateit-font .rateit-range > div {
    background: 0 0;
    overflow: hidden;
    cursor: default;
    white-space: nowrap;
}
.rateit-font .rateit-empty {
    color: #ced4da;
}
.rateit .rateit-hover-rtl,
.rateit .rateit-selected-rtl {
    right: auto;
}
.irs {
    position: relative;
    display: block;
    -webkit-touch-callout: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    font-size: 12px;
}
.irs-line {
    position: relative;
    display: block;
    overflow: hidden;
    outline: 0 !important;
}
.irs-bar {
    position: absolute;
    display: block;
    left: 0;
    width: 0;
}
.irs-shadow {
    position: absolute;
    display: none;
    left: 0;
    width: 0;
}
.irs-handle {
    position: absolute;
    display: block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: default;
    z-index: 1;
}
.irs-handle.type_last {
    z-index: 2;
}
.irs-max,
.irs-min {
    position: absolute;
    display: block;
    cursor: default;
}
.irs-min {
    left: 0;
    right: auto;
}
.irs-max {
    right: 0;
    left: auto;
}
[dir='rtl'] .irs-min {
    right: 0;
    left: auto;
}
[dir='rtl'] .irs-max {
    left: 0;
    right: auto;
}
.irs-from,
.irs-single,
.irs-to {
    position: absolute;
    display: block;
    top: 0;
    cursor: default;
    white-space: nowrap;
}
.irs-grid {
    position: absolute;
    display: none;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 20px;
}
.irs-with-grid .irs-grid {
    display: block;
}
.irs-grid-pol {
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 8px;
    background: #6c757d;
}
.irs-grid-pol.small {
    height: 4px;
}
.irs-grid-text {
    position: absolute;
    bottom: 0;
    left: 0;
    white-space: nowrap;
    text-align: center;
    font-size: 9px;
    line-height: 9px;
    padding: 0 3px;
    color: #6c757d;
}
.irs-disable-mask {
    position: absolute;
    display: block;
    top: 0;
    left: -1%;
    width: 102%;
    height: 100%;
    cursor: default;
    background: rgba(0, 0, 0, 0);
    z-index: 2;
}
.lt-ie9 .irs-disable-mask {
    background: #6c757d;
    cursor: not-allowed;
}
.irs-disabled {
    opacity: 0.4;
}
.irs-hidden-input {
    position: absolute !important;
    display: block !important;
    top: 0 !important;
    left: 0 !important;
    width: 0 !important;
    height: 0 !important;
    font-size: 0 !important;
    line-height: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: hidden;
    outline: 0 !important;
    z-index: -9999 !important;
    background: 0 0 !important;
    border-style: solid !important;
    border-color: transparent !important;
}
.irs--flat {
    height: 40px;
}
.irs--flat.irs-with-grid {
    height: 60px;
}
.irs--flat .irs-line {
    top: 25px;
    height: 12px;
    background-color: #eef2f7;
    border-radius: 4px;
}
.irs--flat .irs-bar {
    top: 25px;
    height: 12px;
    background-color: #536de6;
}
.irs--flat .irs-bar--single {
    border-radius: 4px 0 0 4px;
}
.irs--flat .irs-shadow {
    height: 1px;
    bottom: 16px;
    background-color: #eef2f7;
}
.irs--flat .irs-handle {
    top: 22px;
    width: 16px;
    height: 18px;
    background-color: transparent;
}
.irs--flat .irs-handle > i:first-child {
    position: absolute;
    display: block;
    top: 0;
    left: 50%;
    width: 2px;
    height: 100%;
    margin-left: -1px;
    background-color: #3453e1;
}
.irs--flat .irs-max,
.irs--flat .irs-min {
    top: 0;
    padding: 1px 3px;
    color: #6c757d;
    font-size: 10px;
    line-height: 1.333;
    text-shadow: none;
    background-color: #eef2f7;
    border-radius: 4px;
}
.irs--flat .irs-from,
.irs--flat .irs-single,
.irs--flat .irs-to {
    color: #fff;
    font-size: 10px;
    line-height: 1.333;
    text-shadow: none;
    padding: 1px 5px;
    background-color: #536de6;
    border-radius: 4px;
}
.irs--flat .irs-from:before,
.irs--flat .irs-single:before,
.irs--flat .irs-to:before {
    position: absolute;
    display: block;
    content: '';
    bottom: -6px;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: -3px;
    overflow: hidden;
    border: 3px solid transparent;
    border-top-color: #536de6;
}
.irs--flat .irs-grid-pol {
    background-color: #eef2f7;
}
.irs--flat .irs-grid-text {
    color: #6c757d;
}
[dir='rtl'] .irs-bar--single {
    border-radius: 0 4px 4px 0 !important;
}
.jstree-default {
    padding: 2px 6px;
    height: auto;
}
.jstree-default .jstree-clicked,
.jstree-default .jstree-hovered {
    background: #eef2f7;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.jstree-default .jstree-anchor,
.jstree-default .jstree-icon,
.jstree-default .jstree-icon:empty {
    line-height: 20px;
}
.jstree-wholerow.jstree-wholerow-clicked,
.jstree-wholerow.jstree-wholerow-hovered {
    background: #eef2f7;
}
.gantt .bar-progress {
    fill: #536de6;
}
.gantt .bar-wrapper:hover .bar-progress {
    fill: #6980e9;
}
.gantt .bar,
.gantt .bar-wrapper:hover .bar {
    fill: #eef2f7;
}
.gantt .bar-label,
.gantt .bar-label.big {
    fill: #6c757d;
}
.gantt .grid-header {
    fill: #f1f3fa;
    stroke: #f1f3fa;
}
.gantt .grid-row {
    fill: transparent;
}
.gantt .grid-row:nth-child(even) {
    fill: #f1f3fa;
}
.gantt .lower-text,
.gantt .upper-text {
    fill: #6c757d;
}
.gantt .row-line {
    stroke: #eef2f7;
}
.gantt .today-highlight {
    fill: #eef2f7;
}
.gantt-container .popup-wrapper .pointer {
    display: none;
}
/*# sourceMappingURL=app-modern.min.css.map */
