.patient-results {
    position: absolute;
    width: calc(100% - 30px);
    background: #fff;
    border: solid 1px #ccc;
    border-radius: 3px;
    padding: 0 15px;
    top: 40px;
    z-index: 9;
    max-height: 300px;
    overflow-y: scroll;
}
