label {
    margin-right: 10px;
}

.tablerow :nth-child(even) {
    background-color: lightgray;
}

.dashboard {
    &-status {
        border-left: 3px solid #f9c851;
        &.Cleared {
            border-color: #10c469;
        }
        &.Not.Cleared {
            border-color: #ff5b5b;
        }
    }
    &-start {
        position: absolute;
        top: -15px;
    }
}

.btn--view:visited {
    background-color: var(--bs-white);
    border-color: var(--bs-white);
    color: var(--bs-primary);
}