body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding: 0;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

.app {
    &-error-alert {
        position: fixed;
        top: 15px;
        right: 15px;
        z-index: 10000;
        @media all and (max-width: 768px) {
            width: 95%;
            left: 2.5%;
        }
    }
}

label.required:after {
    content: ' *';
    color: rgb(201, 81, 81);
}

.text-red {
    color: rgb(201, 81, 81);
}

.cursor-pointer {
    cursor: pointer;
}

.fit-content {
    height: fit-content;
}